import { Trans } from '@lingui/macro';
import c from 'classnames';
import React, { FC, MouseEvent, useState } from 'react';

import Modal from 'common/Modal/Modal';
import { TextDisplay } from 'uikit/Text';

import Checkbox, { Checkbox$Props } from '../Checkbox';
import './PreventCheckbox.scss';

type Props = Checkbox$Props & {
  name: string;
  email?: boolean;
  setFieldValue: (name: string, value: boolean) => void;
};

const PreventCheckbox: FC<Props> = (props) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  // extract setFieldValue to not pass to checkbox
  const { setFieldValue, email, ...rest } = props;

  const onChange = (e: MouseEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget;

    if (checked) {
      setFieldValue(props.name, true);
    } else {
      setIsModalOpen(true);
    }
  };

  const uncheck = (): void => {
    setFieldValue(props.name, false);
    setIsModalOpen(false);
  };

  const modalButtonsCN = c('preventCheckbox__modalButtons', {
    preventCheckbox__modalButtons_email: email,
  });

  return (
    <div className="preventCheckbox">
      <Checkbox {...rest} onChange={onChange} />
      <Modal isOpen={isModalOpen} handleClose={() => setIsModalOpen(false)} className="preventCheckboxModal">
        <div className="preventCheckbox__content">
          <TextDisplay bold className="preventCheckbox__modalTitle">
            {email ? <Trans>Czy na pewno chcesz wyłączyć powiadomienia e-mail?</Trans> : <Trans>Na pewno?</Trans>}
          </TextDisplay>
          <p className="preventCheckbox__modalDescription">
            {email ? (
              <Trans id="notifications.disable_email_warning">
                Przestaniemy wysyłać Ci powiadomienia o nowych zapytaniach i wiadomościach od klientów na skrzynkę
                mailową
              </Trans>
            ) : (
              <Trans id="notifications.disable_channel_warning">
                Jeśli odznaczysz to pole, nie będziesz w pełni korzystać z Fixly – nie dostaniesz informacji o nowych
                zapytaniach i wiadomościach od klientów.
              </Trans>
            )}
          </p>
          <div className={modalButtonsCN}>
            <button type="button" className="preventCheckbox__modalButton_unsubscribe" onClick={uncheck}>
              {email ? <Trans>Tak, chcę wyłączyć powiadomienia</Trans> : <Trans>Odznacz</Trans>}
            </button>

            <button type="button" className="preventCheckbox__modalButton_close" onClick={() => setIsModalOpen(false)}>
              {email ? <Trans>Nie, dziękuję</Trans> : <Trans>Anuluj</Trans>}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PreventCheckbox;
