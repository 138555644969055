import { Plural, Trans } from '@lingui/macro';
import c from 'classnames';
import React, { FC, useState, memo } from 'react';
import nl2ReactBR from 'react-nl2br';
import { Link } from 'react-router-dom';

import { useCurrentUser } from 'Components/CurrentUser/CurrentUser';
import ImageSlider from 'common/ImageSlider/ImageSlider';
import RatingStars from 'common/RatingStars/RatingStars';
import Tooltip from 'common/Tooltip/Tooltip';
import platformDetector from 'common/helpers/platformDetector';
import { SyncIcon } from 'common/icons/SyncIcon';
import Avatar from 'uikit/Avatar/Avatar';
import { LinkButton } from 'uikit/Button';
import { TextMeta, TextP3 } from 'uikit/Text';
import routes from 'utils/routeTranslator';

import { formatDate, PublicProfileFeedback } from '../ProviderFeedbacks.helpers';
import { ReactComponent as SVGArrow } from '../assets/arrow.svg';
import { ReactComponent as ExternalIcon } from '../assets/external.svg';
import { ReactComponent as SVGFixlyLogo } from 'common/images/fixly-logo.svg';
import './ProviderFeedbacksItem.scss';
import ReplyForm from './ReplyForm';

interface Props {
  feedback: PublicProfileFeedback;
  provider: {
    id: string;
    firstName: string;
    avatarUrl: string;
  };
}

const isMobile = platformDetector.isAnyMobile();

const ProviderFeedbacksItem: FC<Props> = ({ feedback, provider }) => {
  const [isReplyOpen, setIsReplyOpen] = useState(false);
  const [optimisticReply, setOptimisticReply] = useState<PublicProfileFeedback['reply'] | null>(null);
  const currentUserId = useCurrentUser().currentUser?.userId;
  const isProvider = useCurrentUser().currentUser?.isProvider;
  const isSameUser = currentUserId?.toString() === provider.id;
  const reply = feedback.reply || optimisticReply;
  const isReplyUnapproved = feedback.reply?.status !== 'approved';
  const files = feedback.files;
  const additionalCategories = feedback.l4CategoryNames?.filter((i) => i !== feedback.categoryName);
  const createdAt = feedback.createdAt;

  return (
    <div className="providerFeedbacksItem">
      <div className="providerFeedbacksItem__side">
        <div className="providerFeedbacksItem__info">
          <div className="providerFeedbacksItem__rating">
            <RatingStars rating={feedback.rating} size="small" />
          </div>
          {createdAt && (
            <TextMeta big className="providerFeedbacksItem__date">
              <time dateTime={createdAt}>{formatDate(createdAt)}</time>
            </TextMeta>
          )}
          {!isMobile && (
            <div className="providerFeedbacksItem__internalLabel">
              {!feedback.isExternal && (
                <>
                  <SVGFixlyLogo aria-hidden />
                  <Trans>Opinia z Fixly</Trans>
                </>
              )}
              {feedback.isExternal && (
                <>
                  <ExternalIcon aria-hidden />
                  <Trans>Opinia zewnętrzna</Trans>
                </>
              )}
            </div>
          )}
        </div>
        <div className="providerFeedbacksItem__author">
          {feedback.authorAvatarUrl && <Avatar size={30} avatarUrl={feedback.authorAvatarUrl} />}
          <div className="providerFeedbacksItem__authorDetails">
            <TextP3 bold className="providerFeedbacksItem__authorName">
              {feedback.authorFirstName}
            </TextP3>
            <TextMeta big className="providerFeedbacksItem__authorCity">
              {feedback.cityName}
            </TextMeta>
          </div>
        </div>
      </div>
      <div className="providerFeedbacksItem__main">
        {isMobile && (
          <div className="providerFeedbacksItem__internalLabel">
            {!feedback.isExternal && (
              <>
                <SVGFixlyLogo aria-hidden />
                <Trans>Opinia z Fixly</Trans>
              </>
            )}
            {feedback.isExternal && (
              <>
                <ExternalIcon aria-hidden />
                <Trans>Opinia zewnętrzna</Trans>
              </>
            )}
          </div>
        )}
        <TextP3 as="div" bold className="providerFeedbacksItem__categoryName">
          {!isProvider && Boolean(feedback.categoryName) && Boolean(feedback.l4Slug) && (
            <Link to={routes.get('front::category', { slug: feedback.l4Slug })}>{feedback.categoryName}</Link>
          )}

          {(isProvider || !feedback.l4Slug) && <span>{feedback.categoryName}</span>}

          {!!additionalCategories?.length && (
            <span className="providerFeedbacksItem__restCategories">
              <span className="providerFeedbacksItem__categoryCount">
                <Plural value={additionalCategories.length} one="+# usługa" few="+# usługi" other="+# usług" />
              </span>
              <Tooltip
                className="providerFeedbacksItem__tooltip"
                contentClassName="providerFeedbacksItem__tooltipContent"
              >
                {additionalCategories.map((category) => (
                  <TextMeta big key={category}>
                    {category}
                  </TextMeta>
                ))}
              </Tooltip>
            </span>
          )}
        </TextP3>
        <TextP3 className="providerFeedbacksItem__comment">{nl2ReactBR(feedback.comment)}</TextP3>
        {files && files.length > 0 && (
          <ImageSlider
            className="providerFeedbacksItem__imageSlider"
            imageClassName="providerFeedbacksItem__thumb"
            maxVisibleImages={5}
            staticPreview
            clickable
            images={files.map((file) => ({ url: file }))}
          />
        )}
        {reply && (!isReplyUnapproved || isSameUser) && (
          <div className="providerFeedbacksItem__reply">
            <LinkButton
              className={c('providerFeedbacksItem__replyToggle', {
                'providerFeedbacksItem__replyToggle_open': isReplyOpen,
              })}
              onClick={() => setIsReplyOpen((isOpen) => !isOpen)}
            >
              <Trans>Odpowiedź od wykonawcy</Trans>
              <SVGArrow aria-hidden />
            </LinkButton>
            <div
              className={c('providerFeedbacksItem__replyContainer', {
                'providerFeedbacksItem__replyContainer_open': isReplyOpen,
              })}
            >
              <div className="providerFeedbacksItem__replyAuthor">
                <Avatar size={30} avatarUrl={provider.avatarUrl} />
                <div className="providerFeedbacksItem__replyDetails">
                  <TextP3 bold className="providerFeedbacksItem__replyName">
                    {provider.firstName} •{' '}
                    <span>
                      <Trans>Wykonawca</Trans>
                    </span>
                  </TextP3>
                  <TextMeta big className="providerFeedbacksItem__replyDate">
                    <time dateTime={reply.createdAt}>{formatDate(reply.createdAt)}</time>
                  </TextMeta>
                </div>
                {isReplyUnapproved && (
                  <div className="providerFeedbacksItem__replyStateIcon">
                    <SyncIcon aria-hidden />
                    <Tooltip className="providerFeedbacksItem__replyStateTooltip">
                      <Trans>Twój komentarz czeka na sprawdzenie</Trans>
                    </Tooltip>
                  </div>
                )}
              </div>
              <TextP3 className="providerFeedbacksItem__replyComment">{nl2ReactBR(reply.comment)}</TextP3>
            </div>
          </div>
        )}
        {!reply && isSameUser && <ReplyForm feedbackId={feedback.id} setOptimisticReply={setOptimisticReply} />}
      </div>
    </div>
  );
};

export default memo(ProviderFeedbacksItem);
