import classnames from 'classnames';
import React, { ReactNode, HTMLProps } from 'react';

import Error from '../Error/Error';
import './Checkbox.scss';

// TODO: there is conflict with 'lable' prop. Need to use inputProps prop in future
export interface Checkbox$Props extends Omit<HTMLProps<HTMLInputElement>, 'label'> {
  id: string;
  label: ReactNode;
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  disabled?: boolean;
  error?: ReactNode;
}

const Checkbox = (props: Checkbox$Props) => {
  const { id, label, error, className, labelClassName, inputClassName, disabled, ...rest } = props;

  const hasError = Boolean(Array.isArray(error) ? error.length : error);
  const errorMessage = Array.isArray(error) ? error[0] : error;

  const wrapCN = classnames('checkbox__wrap', className);
  const inputCN = classnames('checkbox', inputClassName);
  const labelCN = classnames(
    {
      'checkbox__label': true,
      'checkbox__label_disabled': disabled,
      'checkbox__label_error': error,
    },
    labelClassName
  );

  return (
    <div className={wrapCN}>
      <input type="checkbox" id={id} className={inputCN} disabled={disabled} {...rest} />
      <label className={labelCN} htmlFor={id}>
        {label}
      </label>

      {hasError && errorMessage && <Error>{errorMessage}</Error>}
    </div>
  );
};

export default Checkbox;
