import React, { Component, HTMLProps, ReactNode, ChangeEvent } from 'react';
import classnames from 'classnames';

import platformDetector from 'common/helpers/platformDetector';
import Label from '../Label/Label';
import Error from '../Error/Error';

import './Textarea.scss';

type Textarea$Props = {
  id?: string;
  className?: string;
  withFileUpload?: boolean;
  expandable?: boolean;
  autofocus?: boolean;
  error?: ReactNode;
  label?: ReactNode;
  reference?: (el: HTMLTextAreaElement) => void;
} & HTMLProps<HTMLTextAreaElement>;

class Textarea extends Component<Textarea$Props> {
  isDesktop = !platformDetector.isAnyMobile();
  textareaRef: HTMLTextAreaElement | null = null;
  prevHeight: number | null = null;

  registerTextAreaElRef = (el: HTMLTextAreaElement | null) => {
    this.textareaRef = el;
    if (this.props.reference && el) {
      this.props.reference(el);
    }
  };

  handleResize = () => {
    if (!this.textareaRef) {
      return;
    }

    this.prevHeight = parseInt(this.textareaRef.style.height, 10) || 0;

    if (!this.textareaRef) {
      return;
    } // to make flow happy

    this.textareaRef.style.height = 'auto';
    const newHeight = this.textareaRef.scrollHeight;
    this.textareaRef.style.height = `${newHeight}px`;
  };

  handleChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    const { expandable, onChange } = this.props;

    if (typeof onChange === 'function') {
      onChange(e);
    }

    if (expandable) {
      requestAnimationFrame(this.handleResize);
    }
  };

  render() {
    const { id, className, withFileUpload, reference, expandable, onChange, error, label, autofocus, value, ...rest } =
      this.props;

    const textareaCN = classnames(
      {
        'textarea': true,
        'textarea_withFileUpload': withFileUpload,
        'textarea_error': error,
      },
      className
    );

    return (
      <div className="textarea__wrap">
        {label && (
          <Label htmlFor={id} className="textarea__label">
            {label}
          </Label>
        )}
        <textarea
          className={textareaCN}
          ref={this.registerTextAreaElRef}
          onChange={this.handleChange}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autofocus}
          value={value || ''}
          id={id}
          {...rest}
        />
        {error && <Error className="textarea__error">{error}</Error>}
      </div>
    );
  }
}

export default Textarea;
