import classnames from 'classnames';
import React, { FC, ReactChild, ReactNode } from 'react';

import { LinkButton } from 'uikit/Button';
import { TextP3 } from 'uikit/Text';

import './VerificationStatusPanel.scss';

export enum VerificationStatus {
  default,
  verified,
}

export type VerificationStatusProps = {
  className?: string;
  status?: VerificationStatus;
  badge?: ReactChild;
  title: ReactNode;
  action?: string;
  warning?: string;
  onAction?: () => void;
};

const VerificationStatusPanel: FC<VerificationStatusProps> = (props) => {
  const hostCN = classnames('verificationStatusPanel', props.className, {
    verificationStatusPanel_verified: props.status === VerificationStatus.verified,
  });
  return (
    <div className={hostCN}>
      <div className="verificationStatusPanel__badge">{props.badge}</div>
      <div className="verificationStatusPanel__body">
        <div className="verificationStatusPanel__content">
          <TextP3 bold className="verificationStatusPanel__heading">
            {props.title} {props.warning && <span className="verificationStatusPanel__warning">({props.warning})</span>}
          </TextP3>
          {Boolean(props.children) && <div className="verificationStatusPanel__children">{props.children}</div>}
        </div>
        {props.action && (
          <LinkButton className="verificationStatusPanel__action" onClick={props.onAction}>
            {props.action}
          </LinkButton>
        )}
      </div>
    </div>
  );
};

export default VerificationStatusPanel;
