import { useQuery } from '@apollo/react-hooks';
import { t } from '@lingui/macro';
import classnames from 'classnames';
import React, { FC, useState, MouseEvent } from 'react';

import { ArrowRightIcon } from 'common/icons/ArrowRightIcon';
import categoryIcon from 'uikit/Icons/Categories';
import routes from 'utils/routeTranslator';

import ProfileEditButton from '../ProfileEditButton/ProfileEditButton';
import { getProviderProfileTitle } from '../ProviderProfileView.helpers';
import { ProviderSectionHeader } from '../ProviderSectionHeader/ProviderSectionHeader';
import { providerFeedbacksQuery, TProviderFeedbacksResponse } from './ProviderServices.helpers';
import './ProviderServices.scss';

export interface ServiceNode {
  id: string;
  name: string;
  slug: string;
  children?: ServiceNode[];
}

type ProviderServicesProps = {
  id?: string;
  className?: string;
  services: ServiceNode[];
  citySlug?: string;
  isProvider?: boolean;
  slug: string;
  onServiceSelect?: (slug: string) => void;
  editable?: boolean;
};

const ProviderServices: FC<ProviderServicesProps> = (props) => {
  const [expandedId, setExpandedId] = useState<string>(props.services[0]?.id); // first item is open by default

  const servicesCount = props.services.flatMap((s) => s.children).length;
  const isExpanded = (id: string): boolean => id === expandedId;

  const onL2Select = (id: string, key?: KeyboardEvent['key']) => {
    if (key) {
      if (['Enter', 'Spacebar', ' '].includes(key)) setExpandedId(id);
    } else {
      setExpandedId(id);
    }
  };

  const { data } = useQuery<TProviderFeedbacksResponse>(providerFeedbacksQuery, {
    variables: {
      slug: props.slug,
    },
  });

  if (!data) return null;

  const {
    profileBySlug: { companyName, firstName, lastName },
  } = data;

  const onL4Select = (slug: string, event: MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    if (props.onServiceSelect) props.onServiceSelect(slug);
  };

  const getServicePath = (slug: string): string => {
    return routes.get('front::category', {
      slug,
      city: props.citySlug,
    });
  };

  const hostCN = classnames('providerServices', props.className);
  const getListCN = (id: string): string => {
    return classnames('providerServices__list', {
      providerServices__list_open: isExpanded(id),
    });
  };

  const getPillCN = (id: string): string => {
    return classnames('providerServices__pill', {
      providerServices__pill_active: isExpanded(id),
    });
  };

  return (
    <section className={hostCN} id={props.id}>
      <ProviderSectionHeader
        title={t`Usługi świadczone przez ${getProviderProfileTitle(firstName, lastName, companyName)}`}
        count={servicesCount}
        editButton={props.editable && <ProfileEditButton href={routes.get('provider::settings.getServices')} />}
      />
      <div className="providerServices__body">
        {props.services.map((s) => (
          <div key={s.id}>
            <h3
              className={getPillCN(s.id)}
              role="button"
              tabIndex={0}
              onClick={() => onL2Select(s.id)}
              onKeyPress={(event) => onL2Select(s.id, event.key)}
              aria-expanded={isExpanded(s.id)}
              aria-controls={`services-list-${s.id}`}
            >
              <span className="providerServices__icon" aria-hidden="true">
                {categoryIcon(s.slug)}
              </span>
              <span className="providerServices__nameGroup">
                {s.name} <span className="providerServices__count">({s.children?.length})</span>
              </span>
              <ArrowRightIcon className="providerServices__chevron" />
            </h3>
            <ul className={getListCN(s.id)} id={`services-list-${s.id}`}>
              {s.children &&
                s.children.map((item) => (
                  <li key={item.id}>
                    <a
                      className="providerServices__category"
                      href={props.isProvider ? undefined : getServicePath(item.slug)}
                      onClick={(event) => onL4Select(item.slug, event)}
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
            </ul>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ProviderServices;
