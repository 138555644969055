import { t, Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, { FC, Fragment, memo, ReactEventHandler, ReactFragment, ReactNode } from 'react';

import ProfileEditButton from 'Components/ProviderProfileView/ProfileEditButton/ProfileEditButton';
import ShadowText from 'Components/ShadowText/ShadowText';
import VerifiedIcon from 'Components/VerifiedIcon/VerifiedIcon';
import ImageSlider from 'common/ImageSlider/ImageSlider';
import ProviderAvatarWithBadge from 'common/ProviderAvatar/ProviderAvatarWithBadge/ProviderAvatarWithBadge';
import ProviderBadges, { ProfileBadge, ProfileBadgeId } from 'common/ProviderBadges/ProviderBadges';
import RatingView from 'common/RatingView/RatingView';
import OnboardingTooltip from 'common/Tooltip/OnboardingTooltip';
import { ReactComponent as ClearIcon } from 'common/icons/clear-circle-filled.svg';
import { ReactComponent as FaceIcon } from 'common/icons/face.svg';
import { ReactComponent as PhoneIcon } from 'common/icons/phone.svg';
import config from 'config/config';
import { LinkButton } from 'uikit/Button';
import { TextMeta, TextP3 } from 'uikit/Text';
import analytics from 'utils/analytics/analytics';
import routes from 'utils/routeTranslator';

import './ProCardMessage.scss';

type ProfileGalleryItem = {
  url: string;
  title?: string;
  order: number;
};

const ProfileBadgesSection: FC<{ badges: ProfileBadge[] }> = (props) => {
  const badgesToRender = props.badges.filter((badge) => {
    return [
      ProfileBadgeId.VerifiedCompany,
      ProfileBadgeId.IkeaExperience,
      ProfileBadgeId.PayWithFixly,
      ProfileBadgeId.Company,
      ProfileBadgeId.PrivatePerson,
      ProfileBadgeId.Insurance,
    ].includes(badge.id);
  });

  if (!badgesToRender.length) return null;
  return (
    <div className="proCardMessage__badgesWrapper">
      <ProviderBadges smallMode badges={badgesToRender} />
    </div>
  );
};

const ProfileStatsSection: FC<{ badges: ProfileBadge[] }> = (props) => {
  const stats: ProfileBadge[] = props.badges.filter((badge) =>
    [ProfileBadgeId.YearsOfExperience, ProfileBadgeId.TimeOnFixly, ProfileBadgeId.ResponseTime].includes(badge.id)
  );
  if (!stats.length) return null;

  return (
    <TextMeta className="proCardMessage__stats">
      {stats.map((item, index) => {
        return (
          <Fragment key={item.id}>
            {index > 0 ? ' • ' : ''} <span>{item.title}</span>
          </Fragment>
        );
      })}
    </TextMeta>
  );
};

const DescriptionSection: FC<{ shortDescription?: string; replaceEmpty?: boolean; editable?: boolean }> = (props) => {
  const { shortDescription } = props;
  const shortDescriptionCN = classnames('proCardMessage__shortDescription', {
    proCardMessage__shortDescription_empty: !shortDescription,
  });
  const placeholder = t`Przedstaw się klientom w kilku zdaniach. Napisz czym się zajmujesz i jak możesz im pomóc.`;

  return (
    <div className="proCardMessage__descriptionWrapper">
      <p className={shortDescriptionCN}>{props.replaceEmpty && !shortDescription ? placeholder : shortDescription}</p>
      {props.editable && <ProfileEditButton id="section-about" />}
    </div>
  );
};

const CategoriesSection: FC<{ requestCategoryName?: string; count?: number; onClick: () => void }> = (props) => {
  const { requestCategoryName, count } = props;

  if (!requestCategoryName || typeof count !== 'number') return null;

  return (
    <div className="proCardMessage__providerCategoriesContainer">
      <span className="proCardMessage__providerCategoriesLabel">
        <Trans>Usługi</Trans>:
      </span>
      <span className="proCardMessage__providerCategoriesActive">{requestCategoryName}</span>
      {count - 1 > 0 && (
        <button type="button" className="proCardMessage__providerCategoriesOther" onClick={props.onClick}>
          {`+${count - 1}`}
        </button>
      )}
    </div>
  );
};

type Props = {
  fullName: string;
  avatarUrl: string;
  address: string;
  rating: number;
  feedbacksCount: number;
  feedbackClientsCount: number;
  featured?: boolean;
  newcomer?: boolean;
  phoneNumber?: string;
  phoneVerified?: boolean;
  gallery?: ProfileGalleryItem[];
  shortDescription?: string | null;
  // NOTE: this type got removed, refactor with some meaningful value
  replaceEmpty?: boolean;
  isProvider?: boolean;
  isVerified?: boolean;
  editable?: boolean;
  premium?: boolean;
  slug?: string;
  slugAlias?: string;
  isRatingClickable?: boolean;
  isSlider?: boolean;
  requestCategoryName?: string;
  providerCategoriesCount?: number;
  showAddRatingButton?: boolean;
  badges: ProfileBadge[];
  onGalleryOpen?: () => void;
  onGalleryImageSlide?: () => void;
  onOpenRatingModal?: (rating: number, touchPointButton: string) => void;
  onShowPhone?: () => void;
  showPhone?: () => Promise<string>;
  onProviderDataClick?: (isOnRatingClick?: boolean) => void;
  onPhoneVerify?: () => void;
  scrollDown?: () => void;
};

const ProCardMessage: FC<Props> = (props) => {
  const siteUrl = config.SITE_URL;

  const getProfileUrl = () => {
    const { slug } = props;
    return routes.get('front::user.profile', { username: slug });
  };
  const openRatingModal = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    const { onOpenRatingModal } = props;

    if (typeof onOpenRatingModal !== 'function') {
      return;
    }

    e.preventDefault();
    e.stopPropagation();
    onOpenRatingModal(0, 'businesscard');
  };

  const hasToRenderRating = (rating: number, replaceEmpty: boolean, isProvider: boolean): boolean => {
    return rating > 0 ? true : isProvider ? replaceEmpty : false;
  };

  const onProviderDataClick = (isRatingClickable = false) => {
    const { onProviderDataClick } = props;

    if (typeof onProviderDataClick === 'function') {
      onProviderDataClick(isRatingClickable);
    }
  };

  const handleProfileClick = (event: React.SyntheticEvent, targetKey: string) => {
    event.preventDefault();
    analytics.trackEvent('sp_public_profile_click', {
      touch_point_button: targetKey,
    });
    window.open(getProfileUrl(), '_blank');
  };

  const onContactRowClick: ReactEventHandler<HTMLDivElement> = (event) => {
    const target = event.currentTarget;

    if (target.className.includes('profileEditButton') && !props.premium) {
      analytics.trackEvent('sp_profile_purchase_button_click', {
        purchase_point: 'business_card',
      });
    }
  };

  const renderMainData = (): ReactNode => {
    const { fullName, avatarUrl, address, isVerified, featured, newcomer, editable } = props;
    const profileLinkCN = classnames('proCardMessage__profileLink', {
      proCardMessage__profileLink_verified: isVerified,
    });
    const profileUrl = getProfileUrl();
    return (
      <div className="proCardMessage__mainInfoWrapper">
        <div className="proCardMessage__mainInfo">
          <a
            href={profileUrl}
            className="proCardMessage__avatarWrapper"
            onClick={(event) => handleProfileClick(event, 'avatar')}
            target="_blank"
            rel="noreferrer"
          >
            <ProviderAvatarWithBadge src={avatarUrl} featured={featured} newcomer={newcomer} />
          </a>

          <h4 className="proCardMessage__fullname">
            <a
              href={profileUrl}
              className={profileLinkCN}
              onClick={(event) => handleProfileClick(event, 'provider_name')}
              target="_blank"
              rel="noreferrer"
            >
              {fullName}
            </a>
          </h4>
          <p className="proCardMessage__address">{address}</p>
          {editable && (
            <div className="proCardMessage__mainEdit">
              <ProfileEditButton id="section-info" />
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderRating = () => {
    const { rating, feedbacksCount, feedbackClientsCount, isRatingClickable, onProviderDataClick } = props;
    return isRatingClickable && typeof onProviderDataClick === 'function' ? (
      renderRatingValues(rating, feedbacksCount, feedbackClientsCount)
    ) : (
      <div className="proCardMessage__ratingWrapper">
        {renderRatingValues(rating, feedbacksCount, feedbackClientsCount)}
      </div>
    );
  };

  const renderRatingValues = (rating: number, feedbacksCount: number, feedbackClientsCount: number) => {
    const { showAddRatingButton, isRatingClickable } = props;
    return (
      <div className="proCardMessage__ratingSection">
        <button
          type="button"
          className="proCardMessage__ratingWrapper"
          onClick={() => onProviderDataClick(isRatingClickable)}
        >
          <RatingView
            rating={rating || 0}
            starSize="normal"
            feedbackCount={feedbacksCount}
            feedbackClientsCount={feedbackClientsCount}
            clientsTooltip
          />
        </button>
        {showAddRatingButton && (
          <button type="button" onClick={openRatingModal} className="proCardMessage__addRating">
            + <Trans>dodaj</Trans>
          </button>
        )}
      </div>
    );
  };

  const renderGallery = (): ReactNode => {
    const { editable, gallery = [] } = props;
    return (
      <div className="proCardMessage__galleryWrapper">
        <ImageSlider
          replaceEmpty={!props.isSlider}
          images={gallery.map((item) => ({
            url: item.url,
            title: item.title,
          }))}
          onGalleryOpen={props.onGalleryOpen}
          onGalleryImageSlide={props.onGalleryImageSlide}
          maxVisibleImages={editable ? 4 : 5}
          staticPreview
          clickable={!editable && gallery.length > 0}
          className="proCardMessage__gallery"
          imageClassName="proCardMessage__galleryImage"
        />

        {editable && <ProfileEditButton id="section-about" />}
      </div>
    );
  };

  const renderContacts = (): ReactFragment => {
    const {
      phoneNumber,
      isProvider,
      premium,
      slug,
      slugAlias,
      showPhone,
      onShowPhone,
      editable,
      phoneVerified,
      onPhoneVerify,
    } = props;
    const protocolRegex = /^https?:\/\//;
    const domain = siteUrl.replace(protocolRegex, '');
    const path = slugAlias ? slugAlias : `profile/${slug}`;
    const profileText = editable ? `${domain}/${path}` : t`Sprawdź pełny profil`;
    const slugEditId = premium ? 'section-slug' : 'wallet';

    return (
      <>
        {phoneNumber && (
          <div className="proCardMessage__contactRow">
            <PhoneIcon className="proCardMessage__contactIcon" aria-hidden />
            <div className="proCardMessage__phoneWrap">
              <ShadowText
                text={phoneNumber}
                kind="phone"
                revealFn={showPhone}
                onReveal={onShowPhone}
                hideByDefault={!isProvider}
                className="proCardMessage__phone"
              />
              {phoneVerified && <VerifiedIcon />}
              {!phoneVerified && isProvider && (
                <LinkButton className="proCardMessage__verify" onClick={onPhoneVerify}>
                  <ClearIcon width={14} height={14} aria-hidden />
                  <Trans>Zweryfikuj</Trans>
                </LinkButton>
              )}
            </div>
            {editable && <ProfileEditButton href={routes.get('provider::settings.getPassword')} />}
          </div>
        )}

        <div className="proCardMessage__contactRow" onClick={onContactRowClick}>
          <FaceIcon className="proCardMessage__contactIcon" aria-hidden />
          <a
            href={getProfileUrl()}
            className="proCardMessage__profile"
            onClick={(event) => handleProfileClick(event, 'link')}
            target="_blank"
            rel="noreferrer"
          >
            {profileText}
          </a>
          {editable && <ProfileEditButton id={slugEditId} />}
        </div>
      </>
    );
  };

  const { isProvider, editable, replaceEmpty, shortDescription, phoneNumber, gallery, rating, slug, scrollDown } =
    props;
  const isRatingShown = hasToRenderRating(rating, Boolean(replaceEmpty), Boolean(isProvider));

  return (
    <div className="proCardMessage" id="proCardMessage">
      <div className="proCardMessage__inner">
        {isProvider && !editable && (
          <div className="proCardMessage__settingsLink">
            <ProfileEditButton href={routes.get('provider::profile')} />
          </div>
        )}

        {renderMainData()}

        {isRatingShown && renderRating()}

        <ProfileStatsSection badges={props.badges} />

        <ProfileBadgesSection badges={props.badges} />

        <CategoriesSection
          requestCategoryName={props.requestCategoryName}
          count={props.providerCategoriesCount}
          onClick={() => onProviderDataClick()}
        />

        {(shortDescription || replaceEmpty) && (
          <DescriptionSection
            shortDescription={shortDescription || undefined}
            replaceEmpty={replaceEmpty}
            editable={editable}
          />
        )}

        {renderGallery()}

        {(phoneNumber || slug || replaceEmpty) && renderContacts()}
      </div>
      {isProvider && !editable && !gallery?.length && (
        <OnboardingTooltip
          bottom
          action={() => {
            window.location.href = routes.get('provider::settings.getPublicProfile');
          }}
          ruleset="6_improve_businesscard"
          className="proCardMessage__onboardingTooltip"
          scrollDown={scrollDown}
        >
          <TextP3 bold>
            <Trans>Dodaj zdjęcia swoich prac do profilu - dzięki temu więcej zlecających wybierze Twoją ofertę</Trans>
          </TextP3>
        </OnboardingTooltip>
      )}
    </div>
  );
};

export default memo(ProCardMessage);
