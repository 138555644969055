import classnames from 'classnames';
import React, { ReactNode, FC, memo } from 'react';

import { Checkbox } from '../Checkbox';
import Radio from '../Radio';
import './SelectableItem.scss';

type Props = {
  type: string;
  value: string;
  name: string;
  label: ReactNode;
  isChecked?: boolean;
  child?: ReactNode | null | undefined;
  className?: string;
  labelClassName?: string;
  changeHandler: React.FormEventHandler<HTMLInputElement>;
};

const SelectableItem: FC<Props> = (props) => {
  const { type, value, name, isChecked, changeHandler, className, labelClassName } = props;

  const renderLabel = (): ReactNode => {
    return (
      <>
        {props.label}
        {props.child && props.child}
      </>
    );
  };

  const id = `${type}_${name}_${value}`;
  const blockCN = classnames('selectableItem', className);
  const labelCN = classnames('selectableItem__label', labelClassName);
  const Input = type === 'radio' ? Radio : Checkbox;

  return (
    <div className={blockCN}>
      <Input
        id={id}
        value={value}
        name={name}
        checked={Boolean(isChecked)}
        onChange={changeHandler}
        labelClassName={labelCN}
        label={renderLabel()}
      />
    </div>
  );
};

export default memo(SelectableItem);
