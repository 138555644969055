import { Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import nl2ReactBR from 'react-nl2br';

import { convertUrl } from 'common/Image/Image.helpers';
import ImageGallery from 'common/ImageGallery/ImageGallery';
import { TProfileGalleryImage } from 'pages/SPPublicProfile/SPPublicProfile.helpers';
import { LinkButton } from 'uikit/Button';
import { Heading4 } from 'uikit/Heading';

import ProfileEditButton from '../ProfileEditButton/ProfileEditButton';
import { ProviderAboutProps } from './ProviderAbout.helpers';
import './ProviderAbout.scss';

const ProviderAbout: FC<ProviderAboutProps> = (props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isExpandable, setIsExpandable] = useState<boolean>(false);
  const [isGalleryOpen, setIsGalleryOpen] = useState<boolean>(false);
  const [galleryIndex, setGalleryIndex] = useState<number>(0);
  const paragraphElem = useRef<HTMLParagraphElement>(null);
  const description = nl2ReactBR(props.description);
  const summary = nl2ReactBR(props.summary);

  useEffect(() => {
    if (paragraphElem.current) {
      const isTextClamped = paragraphElem.current.scrollHeight > paragraphElem.current.clientHeight;
      setIsExpandable(isTextClamped);
    }
  }, []);

  const photos = props.photos && props.photos.length > 0 ? props.photos : undefined;

  const getPreviewUrl = (url: string): string => {
    return convertUrl(url, { size: 's' }).withSize || url;
  };

  const renderPhotos = (photos: TProfileGalleryImage[]): ReactNode => {
    const previewSize = 6;
    const thumbs = photos.slice(0, previewSize);
    const moreCount = photos.length - thumbs.length;

    const isLastThumb = (index: number): boolean => thumbs.length - 1 === index;

    const onThumbClick = (index: number): void => {
      setGalleryIndex(index);
      setIsGalleryOpen(true);
    };

    return (
      <div className="providerAbout__photos">
        {thumbs.map((image, index) => (
          <button
            className="providerAbout__thumb"
            type="button"
            key={image.url}
            data-rest={isLastThumb(index) && moreCount > 0 ? moreCount : undefined}
            style={{ backgroundImage: `url(${getPreviewUrl(image.url)})` }}
            title={image.title}
            onClick={() => onThumbClick(index)}
          >
            <span className="visually-hidden">{image.title}</span>
          </button>
        ))}
      </div>
    );
  };

  const hostCN = classnames('providerAbout', props.className);
  const textContentCN = classnames('providerAbout__expandable', {
    providerAbout__expandable_expanded: isExpanded,
  });

  const textContent = props.description ? description : summary;

  return (
    <section className={hostCN} id={props.id}>
      <Heading4 as="h2" className="providerAbout__title">
        <Trans>O mnie</Trans>
        {props.editable && <ProfileEditButton id={props.id} />}
      </Heading4>
      <p className={textContentCN} ref={paragraphElem}>
        {textContent}
      </p>
      {isExpandable && (
        <LinkButton className="providerAbout__toggle" onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? <Trans>Pokaż mniej</Trans> : <Trans>Pokaż więcej</Trans>}
        </LinkButton>
      )}
      {photos && renderPhotos(photos)}
      {photos && (
        <ImageGallery
          images={photos}
          defaultImageIndex={galleryIndex}
          isOpen={isGalleryOpen}
          onClose={() => setIsGalleryOpen(false)}
        />
      )}
    </section>
  );
};
export default ProviderAbout;
