import { t, Trans } from '@lingui/macro';
import React, { FC } from 'react';

import { PayWithFixlyFeatures, featureMessages } from 'Components/Settings/PayWithFixly/Components/Features';
import 'Components/Settings/PayWithFixly/PayWithFixly.scss';
import Modal from 'common/Modal/Modal';
import config from 'config/config';

import './PwfAdvantagesModal.scss';

/**
 * In the beginning this modal was created for provider only,
 * but then happened a requirement to show the similar view for customer.
 * That's why it is by default for SP and additional property customerView.
 */

type Props = {
  isOpen: boolean;
  handleClose?: () => void;
  customerView?: boolean;
};

export const PwfAdvantagesModal: FC<Props> = (props) => {
  const description = props.customerView ? (
    <Trans id="pwf.payments_for_customers_provided_by_payu">
      Płatności Fixly realizowane są przez naszego zaufanego partnera PayU i umożliwiają rozliczenie się z wykonawcą za
      zlecenie. Po ustaleniu szacunkowego kosztu usługi, dokonujesz wpłaty środków, które na czas trwania prac zostają
      zabezpieczone w PayU. Po potwierdzeniu przez Ciebie realizacji usługi, pieniądze zostaną przekazane wykonawcy.
    </Trans>
  ) : (
    <Trans id="pwf.payments_for_sps_provided_by_payu">
      Płatności Fixly realizowane są przez naszego zaufanego partnera PayU i umożliwiają rozliczenie się z klientem za
      wykonanie usługi. Zlecający przelewa pieniądze z góry. Środki zabezpieczone są w PayU na czas trwania prac, a po
      wykonaniu usługi przekazane na Twoje konto bankowe.
    </Trans>
  );

  const buttonTitle = props.customerView ? t`Wróć do czatu` : t`Ok, rozumiem`;

  const helpDeskUrl = props.customerView
    ? config.FIX_HELPDESK_FAQ_FOR_CUSTOMERS_URL
    : config.FIX_HELPDESK_PAY_WITH_FIXLY_URL;

  return (
    <Modal isOpen={props.isOpen} handleClose={props.handleClose} className="pwfAdvantagesModal">
      <PayWithFixlyFeatures
        className="pwfAdvantagesModal__content"
        title={
          <Trans>
            Płatności Fixly
            <br />
            Jak to działa?
          </Trans>
        }
        description={description}
        listTitle={props.customerView ? t`Oto Twoje korzyści:` : undefined}
        features={props.customerView ? featureMessages.customer : undefined}
        buttonTitle={buttonTitle}
        helpDeskUrl={helpDeskUrl}
        onButtonClicked={props.handleClose}
      />
    </Modal>
  );
};

export default PwfAdvantagesModal;
