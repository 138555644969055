import { t } from '@lingui/macro';
import React, { FC, ReactFragment, useContext } from 'react';
import { Link } from 'react-router-dom';

import { ProviderProfileEditContext } from 'Components/DashboardProviderProfile/DashboardProviderProfile.helpers';
import { ReactComponent as EditIcon } from 'common/icons/pencil-outline.svg';
import { LinkButton } from 'uikit/Button';

import './ProfileEditButton.scss';

type ProfileEditButtonProps =
  | {
      id: string;
    }
  | {
      href: string;
    };

const ProfileEditButton: FC<ProfileEditButtonProps> = (props) => {
  const { setId } = useContext(ProviderProfileEditContext);
  const label: string = t`Edytuj`;

  const renderInner = (): ReactFragment => {
    return (
      <>
        <EditIcon className="profileEditButton__icon" aria-hidden />
        <span className="visually-hidden">{label}</span>
      </>
    );
  };

  if ('href' in props) {
    return (
      <Link className="profileEditButton" to={props.href} title={label}>
        {renderInner()}
      </Link>
    );
  } else {
    return (
      <LinkButton className="profileEditButton" onClick={() => setId(props.id)} title={label}>
        {renderInner()}
      </LinkButton>
    );
  }
};

export default ProfileEditButton;
