import { t, Trans } from '@lingui/macro';
import React, { ReactFragment, FC, useState } from 'react';

import { ServiceNode } from 'Components/ProviderProfileView/ProviderServices/ProviderServices';
import { IKEA_SLUG } from 'common/CustomRoutes/Routes';
import Modal from 'common/Modal/Modal';
import { Button } from 'uikit/Button';
import categoryIcon from 'uikit/Icons/Categories';
import SelectableItem from 'uikit/SelectableItem/SelectableItem';
import { TextP3 } from 'uikit/Text';
import analytics from 'utils/analytics/analytics';

import './SelectServicesModal.scss';

type DialogStep = 'l2' | 'l4';

type Props = {
  isOpen: boolean;
  services: ServiceNode[];
  onServiceSelect: (slug: string) => void;
  handleClose: () => void;
};

const SelectServicesModal: FC<Props> = (props) => {
  const isSingleL2 = props.services.length === 1;
  const initialStep = isSingleL2 ? 'l4' : 'l2';
  const initialCategory = isSingleL2 ? props.services[0].slug : '';
  const [step, setStep] = useState<DialogStep>(initialStep);
  const [category, setCategory] = useState<string>(initialCategory);
  const [selectedSlug, setSelectedSlug] = useState<string>('');

  const onL4Submit = (slug?: string) => {
    analytics.trackEvent('pre_wizard_select_l4');
    props.onServiceSelect(slug || selectedSlug);
    reset();
  };

  const onL2Submit = () => {
    const children = getChildServicesBySlug(category, props.services);
    const hasNestedChoice = children.length > 1;

    if (hasNestedChoice) {
      setStep('l4');
      analytics.trackEvent('pre_wizard_select_l2');
    } else {
      onL4Submit(children[0].slug);
    }
  };

  const renderL2Label = (slug: string, name: string): ReactFragment => (
    <>
      <span className="selectServicesModal__l2Icon">{categoryIcon(slug)}</span>
      <TextP3 as="span" className="selectServicesModal__l2Name">
        {name}
      </TextP3>
    </>
  );

  const handleClose = () => {
    reset();
    props.handleClose();
  };

  const reset = () => {
    setStep(initialStep);
    setCategory(initialCategory);
    setSelectedSlug('');
  };

  const hasChecked = (items: ServiceNode[], slug: string): boolean => {
    return items.some((service) => service.slug === slug);
  };

  const getChildServicesBySlug = (slug: string, services: ServiceNode[]): ServiceNode[] => {
    const result = services.find((s) => s.slug === slug);

    const children = result?.children || [];
    return children.filter((child) => child.slug !== IKEA_SLUG);
  };

  const modalTitle = step === 'l2' ? t`Jaka kategoria Cię interesuje?` : t`Wybierz usługę, której potrzebujesz:`;

  return (
    <Modal isOpen={props.isOpen} title={modalTitle} handleClose={handleClose}>
      {step === 'l2' && (
        <div className="selectServicesModal__options">
          {props.services.map((l2) => (
            <SelectableItem
              type="radio"
              key={l2.slug}
              value={l2.slug}
              name="l2Categories"
              labelClassName="selectServicesModal__l2Item"
              isChecked={l2.slug === category}
              changeHandler={() => setCategory(l2.slug)}
              label={renderL2Label(l2.slug, l2.name)}
            />
          ))}
          <div className="selectServicesModal__buttons">
            <Button
              kind="cta"
              onClick={onL2Submit}
              disabled={!hasChecked(props.services, category)}
              className="selectServicesModal__button"
            >
              <Trans>Dalej</Trans>
            </Button>
          </div>
        </div>
      )}

      {step === 'l4' && (
        <div className="selectServicesModal__options">
          {getChildServicesBySlug(category, props.services).map((l4) => (
            <SelectableItem
              type="radio"
              value={l4.slug}
              name="l4Categories"
              key={l4.slug}
              isChecked={l4.slug === selectedSlug}
              changeHandler={() => setSelectedSlug(l4.slug)}
              label={l4.name}
            />
          ))}
          <div className="selectServicesModal__buttons">
            {!isSingleL2 && (
              <Button kind="secondary" onClick={() => setStep('l2')} className="selectServicesModal__button">
                <Trans>Powrót</Trans>
              </Button>
            )}
            <Button
              kind="cta"
              onClick={() => onL4Submit()}
              disabled={!hasChecked(getChildServicesBySlug(category, props.services), selectedSlug)}
              className="selectServicesModal__button"
            >
              <Trans>Dalej</Trans>
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default SelectServicesModal;
