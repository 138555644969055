import React from 'react';

import { Checkbox } from 'uikit/Checkbox';

import { ReactComponent as SVGStar } from './assets/star.svg';

interface Props {
  rate: number;
  percentage: number;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
}

const StarFilterItem = ({ checked, rate, percentage, onChange }: Props) => {
  const handleChange = () => onChange?.(!checked);
  return (
    <div className="starFilterItem">
      <Checkbox
        checked={checked}
        labelClassName="starFilterItem__label"
        label={
          <>
            <SVGStar className="starFilterItem__svg" />
            <span className="starFilterItem__rate">{rate}</span>
            <div className="starFilterItem__progressBar">
              <div className="starFilterItem__progressBarProgress" style={{ width: `${percentage}%` }}></div>
            </div>
            <div className="starFilterItem__percentage">{Math.round(percentage)} %</div>
          </>
        }
        onChange={handleChange}
        id={`reviews-star-rate-${rate}`}
        className="starFilterItem__checkbox"
      />
    </div>
  );
};

export default StarFilterItem;
