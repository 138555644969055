import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { FC } from 'react';

import Modal from 'common/Modal/Modal';
import { Company } from 'pages/SPPublicProfile/SPPublicProfile.helpers';
import { TextP1 } from 'uikit/Text';

import './ProviderCompanyModal.scss';

type Props = {
  company?: Company;
  isOpen: boolean;
  handleClose?: () => void;
};

const ProviderCompanyModal: FC<Props> = (props) => {
  const { i18n } = useLingui();
  if (!props.company) return null;

  const formatDate = (date: string): string => {
    return i18n.date(date, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  };

  const { nip, foundationDate, name, address, contactName } = props.company;

  return (
    <Modal
      isOpen={props.isOpen}
      title={t`Dane rejestrowe`}
      handleClose={props.handleClose}
      bodyClassName={'providerCompanyModal__body'}
    >
      <div className="providerCompanyModal__row providerCompanyModal__row_grouped">
        <div>
          <TextP1 className="providerCompanyModal__label">NIP</TextP1>
          {nip}
        </div>
        {foundationDate && (
          <div>
            <TextP1 className="providerCompanyModal__label">
              <Trans>Data rozpoczęcia działalności</Trans>
            </TextP1>
            {formatDate(foundationDate)}
          </div>
        )}
      </div>
      {name && (
        <div className="providerCompanyModal__row">
          <TextP1 className="providerCompanyModal__label">
            <Trans>Nazwa Firmy</Trans>
          </TextP1>
          {name}
        </div>
      )}
      {address && (
        <div className="providerCompanyModal__row">
          <TextP1 className="providerCompanyModal__label">
            <Trans>Adres Firmy</Trans>
          </TextP1>
          {address}
        </div>
      )}
      {contactName && (
        <div className="providerCompanyModal__row">
          <TextP1 className="providerCompanyModal__label">
            <Trans>Osoba kontaktowa</Trans>
          </TextP1>
          {contactName}
        </div>
      )}
    </Modal>
  );
};

export default ProviderCompanyModal;
