import React, { FC, useContext } from 'react';

import { ProviderProfileEditContext } from 'Components/DashboardProviderProfile/DashboardProviderProfile.helpers';
import analytics from 'utils/analytics/analytics';

import './ProviderExternalLinks.scss';

type Props = {
  links: string[];
};

const placeholderPrefix = 'placeholder:';

const ProviderExternalLinks: FC<Props> = (props) => {
  const { setId } = useContext(ProviderProfileEditContext);
  if (!props.links.length) return null;

  const isPlaceholder = (link: string): boolean => link.startsWith(placeholderPrefix);
  const extractPlaceholderText = (link: string): string => {
    return link.replace(placeholderPrefix, '');
  };

  const normalizeUrl = (link: string): string => {
    const hasProtocol = Boolean(link.match(new RegExp('^https?://')));
    return hasProtocol ? link : `https://${link}`;
  };

  const onLinkClick = (url: string): void => {
    analytics.trackEvent('sp_profile_user_external_link_click', {
      value: url,
    });
  };

  return (
    <div className="providerExternalLinks">
      {props.links.map((link) => {
        if (isPlaceholder(link)) {
          return (
            <button
              type="button"
              className="providerExternalLinks__item"
              key={link}
              onClick={() => setId('section-contacts')}
            >
              <span className="providerExternalLinks__truncate">{extractPlaceholderText(link)}</span>
            </button>
          );
        } else {
          return (
            <a
              className="providerExternalLinks__item"
              href={normalizeUrl(link)}
              onClick={() => onLinkClick(link)}
              key={link}
              target="_blank"
              rel="noopener noreferrer nofollow ugc"
            >
              <span className="providerExternalLinks__truncate">{link}</span>
            </a>
          );
        }
      })}
    </div>
  );
};

export default ProviderExternalLinks;
