import { t } from '@lingui/macro';
import React, { FC } from 'react';

import { ReactComponent as CheckIcon } from 'common/icons/check-circle-filled.svg';

type Props = {
  size?: number;
  className?: string;
};

const VerifiedIcon: FC<Props> = (props) => {
  const size = props.size || 14;
  const colorLima = '#7ed321';

  return (
    <CheckIcon
      width={size}
      height={size}
      style={{
        verticalAlign: 'middle',
        color: colorLima,
      }}
      className={props.className}
      title={t`Zweryfikowany`}
      aria-hidden
    />
  );
};

export default VerifiedIcon;
