import classnames from 'classnames';
import React, { FC } from 'react';

import Image from 'common/Image/Image';
import { Image$Props } from 'common/Image/Image.helpers';
import ProviderBadge from 'common/ProviderBadge/ProviderBadge';

import './Avatar.scss';

type Props = {
  avatarUrl: string;
  name?: string;
  size?: number;
  srcSizeModifier?: Image$Props['size'];
  featured?: boolean;
  newcomer?: boolean;
  muted?: boolean;
  className?: string;
};

const Avatar: FC<Props> = (props) => {
  const { muted, avatarUrl, name, featured, newcomer, size, srcSizeModifier, className } = props;

  const avatarCN = classnames(
    'avatar__image',
    {
      'avatar__image_muted': muted,
    },
    className
  );

  return (
    <div className="avatar">
      <Image
        src={avatarUrl}
        size={srcSizeModifier || 's'}
        webp
        defaultJPEG
        multiPixelRatios
        alt={name}
        className={avatarCN}
        width={size}
        height={size}
        lazy
      />
      {(featured || newcomer) && (
        <div className="avatar__badgeWrapper">
          <ProviderBadge featured={featured} newcomer={newcomer} />
        </div>
      )}
    </div>
  );
};

export default Avatar;
