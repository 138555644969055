import classnames from 'classnames';
import React from 'react';

import Image from 'common/Image/Image';
import { IConvertUrl$Size } from 'common/Image/Image.helpers';

import './ProviderAvatar.scss';

export type ProviderAvatar$Props = {
  src: string;
  size?: IConvertUrl$Size;
  className?: string;
};

const ProviderAvatar = ({ className, size = 'm', ...rest }: ProviderAvatar$Props) => (
  <Image {...rest} size={size} className={classnames('providerAvatar', className)} />
);

export default ProviderAvatar;
