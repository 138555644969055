import React from 'react';

export const SyncIcon = (props: React.SVGAttributes<SVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10 3.333v-2.5L6.667 4.167 10 7.5V5c2.758 0 5 2.242 5 5 0 .842-.208 1.642-.583 2.333l1.216 1.217A6.61 6.61 0 0016.667 10 6.665 6.665 0 0010 3.333zM10 15c-2.758 0-5-2.242-5-5 0-.842.208-1.642.583-2.333L4.367 6.45A6.61 6.61 0 003.333 10 6.665 6.665 0 0010 16.667v2.5l3.333-3.334L10 12.5V15z"
      fill="currentColor"
    />
  </svg>
);

/* eslint max-len:0 */
