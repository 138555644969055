import React, { Component, ReactNode } from 'react';
import classnames from 'classnames';

import Textarea from '../Textarea';

import './TextareaWithCounter.scss';

type TextareaWithCounter$Props = {
  placeholder: string;
  name?: string;
  minLength?: number;
  maxLength?: number;
  id?: string;
  value: string;
  label?: string;
  isError?: boolean;
  error?: ReactNode;
  className?: string;
  wrapperClassName?: string;
  expandable?: boolean;
  withFileUpload?: boolean;
  onChange: (value: string) => void;
  onFocus?: () => void;
};

class TextareaWithCounter extends Component<TextareaWithCounter$Props> {
  onFocus = () => {
    const { onFocus } = this.props;

    if (typeof onFocus === 'function') {
      onFocus();
    }
  };

  handleChange = (e: React.SyntheticEvent<HTMLTextAreaElement>) => {
    const { onChange, maxLength } = this.props;
    const value = e.currentTarget.value.slice(0, maxLength);

    onChange(value);
  };

  render() {
    const {
      id,
      placeholder,
      name,
      value,
      label,
      minLength,
      maxLength,
      className,
      wrapperClassName,
      isError,
      error,
      expandable,
      withFileUpload,
    } = this.props;
    const showIncrementCounter =
      (maxLength && !minLength) || (minLength && maxLength && value && value.length >= minLength);

    const containerCN = classnames({
      smartTextarea: true,
      [wrapperClassName || '']: Boolean(wrapperClassName),
    });

    const inputCN = classnames({
      textareaWithCounter__input: true,
      textareaWithCounter__input_error: isError || error,
      [className || '']: Boolean(className),
    });

    const counterCN = classnames({
      'textareaWithCounter__counter': true,
      'textareaWithCounter__counter_withFileUpload': withFileUpload,
    });

    return (
      <div className={containerCN}>
        <div className="textareaWithCounter__inputCounterWrapper">
          <Textarea
            id={id || name}
            name={name}
            className={inputCN}
            label={label}
            placeholder={placeholder}
            value={value}
            onChange={this.handleChange}
            onFocus={this.onFocus}
            withFileUpload={withFileUpload}
            expandable={expandable}
          />
          <div className="textareaWithCounter__counterWrapper">
            {minLength && value && value.length < minLength && (
              <div className={counterCN}>{minLength - value.length}</div>
            )}
            {showIncrementCounter && (
              <div className={counterCN}>
                {value.length}/{maxLength}
              </div>
            )}
          </div>
        </div>
        {error && <span className="textareaWithCounter__errorLabel">{error}</span>}
      </div>
    );
  }
}

export default TextareaWithCounter;
