import { t, Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, { FC, useContext, useState } from 'react';

import { ProviderProfileEditContext } from 'Components/DashboardProviderProfile/DashboardProviderProfile.helpers';
import VerificationStatusPanel, {
  VerificationStatus,
} from 'Components/VerificationStatusPanel/VerificationStatusPanel';
import { ReactComponent as GusBadge } from 'common/badges/gus.svg';
import { Company, VerificationStatus as GusVerificationStatus } from 'pages/SPPublicProfile/SPPublicProfile.helpers';
import { TextMeta } from 'uikit/Text';
import analytics from 'utils/analytics/analytics';

import ProviderCompanyModal from '../ProviderCompanyModal/ProviderCompanyModal';
import './ProviderGus.scss';

type Props = {
  className?: string;
  company?: Company;
};

const ProviderGus: FC<Props> = (props) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const { setId } = useContext(ProviderProfileEditContext);

  const hostCN = classnames('providerGus', props.className);
  const verified = props.company?.verificationStatus === GusVerificationStatus.verified;
  const pending = props.company?.verificationStatus === GusVerificationStatus.inModeration;

  const getAction = () => {
    switch (props.company?.verificationStatus) {
      case GusVerificationStatus.verified:
        return t`Szczegóły`;
      case GusVerificationStatus.inModeration:
        return undefined;
      case GusVerificationStatus.rejected:
        return t`Spróbuj ponownie`;
      case GusVerificationStatus.initial:
      default:
        return t`Zweryfikuj`;
    }
  };

  const getStatus = (): VerificationStatus => {
    if (verified || pending) return VerificationStatus.verified;
    return VerificationStatus.default;
  };

  const getWarning = (): string | undefined => {
    if (verified) return undefined;
    if (pending) return t`W moderacji`;
    return t`Nieaktywne`;
  };

  const onAction = () => {
    verified ? setIsDetailsOpen(true) : setId('section-gus');
    analytics.trackEvent('sp_profile_gus_badge_button_click');
  };

  return (
    <>
      <VerificationStatusPanel
        className={hostCN}
        status={getStatus()}
        badge={<GusBadge />}
        title={t`Dane firmy sprawdzone w GUS`}
        action={getAction()}
        warning={getWarning()}
        onAction={onAction}
      >
        {!verified && !pending && (
          <TextMeta>
            {props.company?.verificationStatus === GusVerificationStatus.rejected ? (
              <Trans>Weryfikacja nie powiodła się.</Trans>
            ) : (
              <Trans>Zweryfikuj swoją firmę i otrzymaj oznaczenie, które wyróżni Cię w oczach klientów.</Trans>
            )}
          </TextMeta>
        )}
      </VerificationStatusPanel>
      {verified && (
        <ProviderCompanyModal
          company={props.company}
          isOpen={isDetailsOpen}
          handleClose={() => setIsDetailsOpen(false)}
        />
      )}
    </>
  );
};

export default ProviderGus;
