import { Trans, Plural } from '@lingui/macro';
import classnames from 'classnames';
import React, { FC } from 'react';

import { TextMeta } from 'uikit/Text';

import { ReactComponent as SVGStar } from '../assets/star.svg';
import './ProviderFeedbacksSummary.scss';

const helpCenterUrl = 'https://pomoc.fixly.pl/hc/pl/articles/5512592608146-Opinie-Wykonawc%C3%B3w-na-Fixly';

interface Props {
  count: number;
  clientsCount: number;
  externalFeedbackCount?: number;
  rating: number;
  align?: 'center' | 'left';
}

export const ProviderFeedbacksSummary: FC<Props> = ({ count, clientsCount, externalFeedbackCount, rating, align }) => {
  const feedbacksSummaryCN = classnames('providerFeedbacksSummary__headerSummary', {
    'providerFeedbacksSummary__center': align === 'center',
  });
  return (
    <div className={feedbacksSummaryCN}>
      <div className="providerFeedbacksSummary__mainRating">
        <SVGStar aria-hidden />
        {rating}
      </div>
      <div className="providerFeedbacksSummary__ratingDescription">
        <TextMeta big>
          {count} <Plural value={count} one="opinia" few="opinie" other="opinii" /> (
          <Plural value={clientsCount} one="od # klienta" other="od # klientów" />)
          {typeof externalFeedbackCount !== 'undefined' && (
            <span className="providerFeedbacksSummary__ratingDescriptionExternal">
              <Trans>
                {externalFeedbackCount}{' '}
                <Plural value={externalFeedbackCount} one="opinia" few="opinie" other="opinii" /> spoza Fixly
              </Trans>
            </span>
          )}
        </TextMeta>
        <TextMeta className="providerFeedbacksSummary__link">
          <a href={helpCenterUrl} target="_blank" rel="noreferrer">
            <Trans>Dowiedz się więcej o opiniach</Trans>
          </a>
        </TextMeta>
      </div>
    </div>
  );
};
