import React, { FC } from 'react';

export type PathItem = {
  url: string;
  name: string;
};

type Props = {
  items: PathItem[];
};

const BreadcrumbList: FC<Props> = (props) => {
  const { items } = props;
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: items.map((item, index) => {
      const isLast = items.length === index + 1;
      const listItem: Record<string, any> = {
        '@type': 'ListItem',
        position: index + 1,
        item: {
          name: item.name,
        },
      };
      if (!isLast) listItem.item['@id'] = item.url;
      return listItem;
    }),
  };
  return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }} />;
};

export default BreadcrumbList;
