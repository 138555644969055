import { t } from '@lingui/macro';
import classnames from 'classnames';
import React from 'react';

import './ProviderBadge.scss';

type ProviderBadgeType = 'featured' | 'newcomer';

interface Props {
  featured?: boolean;
  newcomer?: boolean;
  className?: string;
}

const getBadgeLabel = (type: ProviderBadgeType): string => {
  switch (type) {
    case 'featured': {
      return t`Polecany!`;
    }
    case 'newcomer': {
      return t`Nowy`;
    }
    default:
      throw new Error(`Type ${type} is not supported`);
  }
};

const ProviderBadge: React.FC<Props> = ({ featured, newcomer, className }) => {
  const type = featured ? 'featured' : newcomer && 'newcomer';
  if (!type) return null;
  const badgeCN = classnames('providerBadge', `providerBadge_${type}`, className);
  return <div className={badgeCN}>{getBadgeLabel(type)}</div>;
};

export default ProviderBadge;
