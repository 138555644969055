import { Plural } from '@lingui/macro';
import c from 'classnames';
import React from 'react';

import RatingStars from 'common/RatingStars/RatingStars';
import Tooltip from 'common/Tooltip/Tooltip';
import NotificationCount from 'uikit/NotificationCount/NotificationCount';
import { TextMeta } from 'uikit/Text';

import './RatingView.scss';

export type RatingView$Props = {
  rating?: number;
  feedbackCount: number;
  feedbackClientsCount?: number;
  singleStar?: boolean;
  clientsTooltip?: boolean;
  starSize?: 'big' | 'small' | 'normal' | undefined;
  className?: string;
  starClassName?: string;
  ratingClassName?: string;
  countClassName?: string;
  clientsTooltipClassName?: string;
  clientsClassName?: string;
  feedbackWrapClassName?: string;
  unseenFeedbacksCount?: number;
};

const RatingView = (props: RatingView$Props) => {
  const {
    rating = 0,
    feedbackCount,
    feedbackClientsCount,
    clientsTooltip,
    className,
    starClassName,
    ratingClassName,
    countClassName,
    clientsTooltipClassName,
    clientsClassName,
    feedbackWrapClassName,
    singleStar,
    starSize,
    unseenFeedbacksCount,
  } = props;

  const viewCN = c('ratingView', className);
  const starCN = c('ratingView__star', starClassName);
  const ratingCN = c('ratingView__rating', ratingClassName);
  const feedbackWrapCN = c('ratingView__feedbackWrap', feedbackWrapClassName);
  const countCN = c('ratingView__feedbackCount', countClassName);
  const clientsTooltipCN = c('ratingView__clientsTooltip', clientsTooltipClassName);
  const clientsCN = c('ratingView__clients', clientsClassName);

  return (
    <div className={viewCN}>
      <div className="ratingView__starRating">
        {singleStar ? <span className={starCN} /> : <RatingStars rating={rating} size={starSize} />}

        <span className={ratingCN} data-testid="providerRatingNumber">
          {rating.toFixed(1)}
        </span>
      </div>
      <div className="ratingView__countWrap">
        {feedbackCount > 0 && (
          <div className={feedbackWrapCN}>
            <TextMeta big>
              <span className={countCN}>
                <Plural value={feedbackCount} one="# opinia" few="# opinie" other="# opinii" />
              </span>
            </TextMeta>
            {clientsTooltip && feedbackClientsCount && feedbackClientsCount > 0 && (
              <Tooltip className={clientsTooltipCN} contentClassName="ratingView__clientsTooltipContent">
                <TextMeta big>
                  <Plural value={feedbackClientsCount} one="od # klienta" other="od # klientów" />
                </TextMeta>
              </Tooltip>
            )}
          </div>
        )}

        {!clientsTooltip && feedbackClientsCount && feedbackClientsCount > 0 && (
          <TextMeta big>
            <span className={clientsCN}>
              (<Plural value={feedbackClientsCount} one="od # klienta" other="od # klientów" />)
              {!!unseenFeedbacksCount && unseenFeedbacksCount > 0 && (
                <NotificationCount className="headerRating__newFeedbacks">{unseenFeedbacksCount}</NotificationCount>
              )}
            </span>
          </TextMeta>
        )}
      </div>
    </div>
  );
};
export default RatingView;
