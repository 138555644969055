import { t, Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, { FC, ReactEventHandler, useContext } from 'react';

import { ProviderProfileEditContext } from 'Components/DashboardProviderProfile/DashboardProviderProfile.helpers';
import { ReactComponent as CrownIcon } from 'common/icons/crown.svg';
import { ReactComponent as EnvelopeIcon } from 'common/icons/envelope.svg';
import { ReactComponent as FaceIcon } from 'common/icons/face.svg';
import { ReactComponent as LinkIcon } from 'common/icons/link.svg';
import { ReactComponent as LocationIcon } from 'common/icons/location.svg';
import { ReactComponent as LockIcon } from 'common/icons/lock.svg';
import { ReactComponent as PhoneIcon } from 'common/icons/phone.svg';
import { ReactComponent as WebIcon } from 'common/icons/web.svg';
import config from 'config/config';
import { Button } from 'uikit/Button';
import { TextDisplay, TextMeta } from 'uikit/Text';
import analytics from 'utils/analytics/analytics';

import './ProfileActivationCard.scss';

type Props = {
  className?: string;
};

const ProfileActivationCard: FC<Props> = (props) => {
  const { setId } = useContext(ProviderProfileEditContext);

  const onAction: ReactEventHandler = () => {
    setId('wallet');
    analytics.trackEvent('sp_profile_purchase_button_click', {
      purchase_point: 'profile',
    });
  };
  const hostCN = classnames('profileActivationCard', props.className);

  return (
    <div className={hostCN}>
      <TextDisplay bold className="profileActivationCard__heading">
        <Trans>Dane kontaktowe</Trans> <LockIcon className="profileActivationCard__lock" aria-hidden="true" />
      </TextDisplay>
      <ul className="profileActivationCard__contacts">
        <li className="profileActivationCard__pseudoContact">
          <WebIcon aria-hidden="true" />
          <Trans>Strona internetowa</Trans>
        </li>
        <li className="profileActivationCard__pseudoContact">
          <FaceIcon aria-hidden="true" />
          Facebook
        </li>
        <li className="profileActivationCard__pseudoContact">
          <EnvelopeIcon aria-hidden="true" />
          twojadres@email.com
        </li>
        <li className="profileActivationCard__pseudoContact">
          <PhoneIcon aria-hidden="true" />
          +48 000 000 000
        </li>
        <li className="profileActivationCard__pseudoContact">
          <LocationIcon aria-hidden="true" />
          <Trans>Adres</Trans>
        </li>
      </ul>
      <div className="profileActivationCard__map">{props.children}</div>
      <TextDisplay bold className="profileActivationCard__heading">
        <Trans>Twój adres na Fixly.pl</Trans> <LockIcon className="profileActivationCard__lock" aria-hidden="true" />
      </TextDisplay>
      <div className="profileActivationCard__pseudoContact">
        <LinkIcon aria-hidden="true" />
        {config.SITE_URL}/{t`nazwaTwojejFirmy`}
      </div>
      <div className="profileActivationCard__footer">
        <TextMeta big>
          <Trans>
            Udostępnij swoje dane kontaktowe na profilu, spersonalizuj baner oraz adres w serwisie z opcją premium
          </Trans>{' '}
          <b>
            <Trans>Twoja strona premium</Trans>
          </b>
          .
          <br />
          <a href={config.HELPDESK_PUBLIC_PROFILE_URL} target="_blank" rel="noopener noreferrer">
            <Trans>Sprawdź szczegóły</Trans>
          </a>
        </TextMeta>
        <Button className="profileActivationCard__action" kind="cta" width="full" onClick={onAction}>
          <CrownIcon className="profileActivationCard__icon" aria-hidden="true" /> <Trans>Aktywuj premium</Trans>
        </Button>
      </div>
    </div>
  );
};

export default ProfileActivationCard;
