import { formatDistanceToNow } from 'date-fns';
import { pl } from 'date-fns/locale';

import { ProfileBadge } from 'common/ProviderBadges/ProviderBadges';
import browserDetector from 'common/helpers/browserDetector';
import platformDetector from 'common/helpers/platformDetector';
import config from 'config/config';
import { getCanonicalBySlug } from 'pages/SPPublicProfile/SPPublicProfile.helpers';

export type ProviderInfoProps = {
  className?: string;
  publicName?: string;
  companyName?: string;
  avatarUrl: string;
  coverUrl?: string;
  cityName?: string;
  regionName?: string;
  lastActivity?: string; // date ISO string
  feedbacksCount?: number;
  feedbackClientsCount?: number;
  featured?: boolean;
  rating: number;
  experience: ProfileBadge[];
  editable?: boolean;
  premium?: boolean;
  slug: string;
};

export const isProviderOnline = (lastActivity: string): boolean => {
  const now = new Date();
  const lastSeen = new Date(lastActivity);
  const millisecondsInMinute = 60 * 1000;
  const differenceInMinutes = (now.getTime() - lastSeen.getTime()) / millisecondsInMinute;
  return differenceInMinutes < config.MINUTES_TO_SHOW_ONLINE;
};

export const getLastSeenText = (lastActivity: string): string => {
  return formatDistanceToNow(new Date(lastActivity), {
    addSuffix: true,
    locale: pl,
  });
};

export const getAddressText = (city?: string, region?: string): string => {
  return [city, region].filter((item) => Boolean(item)).join(', ');
};

export const getProfileShareText = (slug: string): string => {
  const url = getCanonicalBySlug(slug);
  return `Serdecznie zapraszam do obejrzenia mojego pełnego profilu na Fixly ${url}`;
};

export const isDesktopSafari = (): boolean => {
  return browserDetector.isSafari && !platformDetector.isAnyMobile();
};
