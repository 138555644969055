import React from 'react';

import { TextMeta } from 'uikit/Text';
import routes from 'utils/routeTranslator';

import { CityNearby } from './ProviderServiceArea.helpers';
import './ProviderServiceArea.scss';

interface Props {
  mapUrl: string;
  nearbyCities?: CityNearby[];
}

const ProviderServiceArea = ({ nearbyCities, mapUrl }: Props) => {
  return (
    <div className="providerServiceArea">
      <div className="providerServiceArea__mapContainer">
        <div className="providerServiceArea__map" style={{ backgroundImage: `url(${mapUrl})` }}></div>
        <span className="providerServiceArea__circle" />
      </div>
      {nearbyCities && nearbyCities.length > 0 && (
        <div className="providerServiceArea__cities">
          <TextMeta>
            {nearbyCities.map((city, i) => [
              i > 0 && ', ',
              <a
                href={routes.get('categories_seo', { city: city.slug })}
                className="providerServiceArea__cityLink"
                key={city.slug}
              >
                {city.name}
              </a>,
            ])}
          </TextMeta>
        </div>
      )}
    </div>
  );
};

export default ProviderServiceArea;
