import gql from 'graphql-tag';

import { client } from 'utils/apolloConnector/apolloConnector';
import localStorage from 'utils/localStorage/localStorage';

import { WizardQuestion } from './RequestWizard.helpers';

export type WizardOpener = {
  type: string;
};

export const searchProvidersQuery = gql`
  query searchProvidersByLonLatCategoryId($lat: Float!, $lng: Float!, $serviceId: ID!, $limit: Int) {
    searchProvidersByLonLatCategoryId(lat: $lat, lon: $lng, categoryId: $serviceId, limit: $limit) {
      total
    }
  }
`;

export type SearchProvidersResponse = {
  searchProvidersByLonLatCategoryId?: {
    total?: number;
  };
};

export const isCategoryPaidQuery = gql`
  query cityL4CategoryPaid($cityId: Int!, $l4Id: Int!) {
    cityL4CategoryPaid(cityId: $cityId, l4Id: $l4Id)
  }
`;

export const currentUserQuery = gql`
  query CurrentUser {
    me {
      id
      emailVerified
      phoneVerified
      firstName
      lastName
      email
      phone
    }
  }
`;

export type CurrentUserResponse = {
  me: {
    id: string;
    emailVerified: boolean;
    phoneVerified: boolean;
    firstName?: string;
    lastName?: string;
    email: string;
    phone?: string;
  };
};

export const l4QuestionsQuery = gql`
  query L4Questions($slug: String!) {
    l4BySlug(slug: $slug, showHiddenCategories: true) {
      id
      name
      slug
      l3 {
        id
        slug
      }
      serviceNotePlaceholder
      questions {
        id
        label
        type
        proLabel
        placeholder
        answers {
          id
          label
        }
      }
    }
  }
`;

export type L4QuestionsResponse = {
  l4BySlug: {
    id: string;
    name: string;
    slug: string;
    l3?: {
      id: string;
      slug: string;
    };
    serviceNotePlaceholder: string;
    questions: WizardQuestion[];
  };
};

export const isCategoryPaid = (cityId: string, l4Id: string): Promise<boolean> => {
  return client
    .query<{ cityL4CategoryPaid: boolean }>({
      query: isCategoryPaidQuery,
      variables: {
        cityId: Number(cityId),
        l4Id: Number(l4Id),
      },
    })
    .then((res) => res.data.cityL4CategoryPaid);
};

export const getProvidersCount = (serviceId: string, location: { lat: number; lng: number }): Promise<number> => {
  const { lat, lng } = location;

  return client
    .query<SearchProvidersResponse>({
      query: searchProvidersQuery,
      variables: {
        lat,
        lng,
        serviceId,
        limit: 0,
      },
    })
    .then((res) => {
      return res.data.searchProvidersByLonLatCategoryId?.total || 0;
    });
};

export const getTouchPointButton = (): string => {
  let touchPointButton = 'direct_l4_page';

  if (localStorage.getItem('wizardTouchPointButton')) {
    touchPointButton = localStorage.getItem('wizardTouchPointButton') || '';
    localStorage.removeItem('wizardTouchPointButton');
  }

  return touchPointButton;
};
