import { isWindow, isDocument } from './helpers';

// TODO: fix the problems GS-9213
/* eslint-disable ssr-friendly/no-dom-globals-in-module-scope*/
export const isOpera =
  /* @ts-expect-error type mismatch */
  isWindow && ((!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0);
/* @ts-expect-error type mismatch */
export const isFirefox = typeof InstallTrigger !== 'undefined';
export const isSafari = isWindow && !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/);
/* @ts-expect-error type mismatch */
export const isIE = isDocument && /* @cc_on!@ */ (false || !!document.documentMode);
/* @ts-expect-error type mismatch */
export const isEdge = isWindow && !isIE && !!window.StyleMedia;
/* @ts-expect-error type mismatch */
export const isChrome = isWindow && !!window.chrome;
/* eslint-enable ssr-friendly/no-dom-globals-in-module-scope*/

const browserDetector = {
  isOpera,
  isFirefox,
  isSafari,
  isIE,
  isEdge,
  isChrome,
};
export default browserDetector;
