import React from 'react';
import classnames from 'classnames';

import './NotificationCount.scss';

type NotificationCount$Props = {
  children: string | number;
  urgent?: boolean;
  className?: string;
};

export default function NotificationCount({ children, className, urgent }: NotificationCount$Props) {
  const CN = classnames('notificationClient', className, {
    'notificationClient_urgent': urgent,
  });

  return <span className={CN}>{children}</span>;
}
