import React, { ReactNode } from 'react';

import { TextDisplay } from 'uikit/Text';

import './ProviderSectionHeader.scss';

interface Props {
  title: string;
  count?: number;
  editButton?: ReactNode;
}

export const ProviderSectionHeader = ({ title, count, editButton }: Props) => {
  return (
    <TextDisplay<'h2'> as="h2" bold className="ProviderSectionHeader">
      <span>
        {title} {!!count && <span className="ProviderSectionHeader__count">{count}</span>}
      </span>
      {editButton && editButton}
    </TextDisplay>
  );
};
