import classnames from 'classnames';
import React from 'react';
import { Link as ScrollLink } from 'react-scroll';

import { TextP3 } from 'uikit/Text';

import './ProfileNavigation.scss';

interface Props {
  className?: string;
  items: {
    label: React.ReactNode;
    id: string;
  }[];
}

const ProfileNavigation = ({ items, className }: Props) => {
  const hostCN = classnames('profileNavigation', className);

  return (
    <ul className={hostCN}>
      {items.map((item) => (
        <li key={item.id}>
          <ScrollLink
            to={item.id}
            spy
            smooth
            offset={-80}
            duration={500}
            className="profileNavigation__link"
            activeClass="profileNavigation__link_active"
          >
            <TextP3>{item.label}</TextP3>
          </ScrollLink>
        </li>
      ))}
    </ul>
  );
};

export default ProfileNavigation;
