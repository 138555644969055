import { Trans } from '@lingui/macro';
import c from 'classnames';
import React, { FC, ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Button } from 'uikit/Button';
import { CheckboxIconList, CheckboxIconListItem } from 'uikit/CheckboxIconList';
import { Heading4 } from 'uikit/Heading';
import { TextP3 } from 'uikit/Text';
import analytics from 'utils/analytics/analytics';

import './Features.scss';

type Props = {
  buttonTitle?: string;
  className?: string;
  title: ReactNode;
  description: ReactNode;
  titleIcon?: boolean;
  withAnalytics?: boolean;
  listTitle?: string;
  features?: ReactNode[];
  helpDeskUrl?: string;
  onButtonClicked?: () => void;
};

export const featureMessages = {
  provider: [
    <Trans>
      <b>więcej klientów</b> - zlecający chcą rozliczać się za pośrednictwem Płatności Fixly
    </Trans>,
    <Trans>
      <b>elastyczność</b> - możesz swobodnie negocjować cenę ze zlecającym
    </Trans>,
    <Trans>
      <b>brak prowizji</b> - 100% środków trafia na Twoje konto
    </Trans>,
    <Trans>
      <b>zwrot punktów</b> za wysłanie oferty, gdy rozliczasz się z klientem przy pomocy Płatności Fixly
    </Trans>,
    <Trans>
      <b>specjalna odznaka</b> i promocja na ekranie zlecającego
    </Trans>,
    <Trans>
      <b>wsparcie Fixly</b> przy rozwiązywaniu kwestii spornych
    </Trans>,
    <Trans>
      <b>pewność zapłaty</b> po prawidłowym wykonaniu zlecenia - pieniądze czekają na Ciebie jeszcze przed rozpoczęciem
      prac
    </Trans>,
  ],
  customer: [
    <Trans>
      <b>bezpieczna transakcja</b> - pieniądze są zabezpieczone i przekazane wykonawcy dopiero, gdy potwierdzisz
      wykonanie usługi
    </Trans>,
    <Trans>
      <b>możliwość negocjacji ceny</b> - wpłaty dokonujesz według szacowanego kosztu usługi, który potwierdzasz z
      wykonawcą. Ewentualną różnicę możesz rozliczyć bezpośrednio z wykonawcą
    </Trans>,
    <Trans>
      <b>brak prowizji</b> za płatność z Fixly
    </Trans>,
    <Trans>
      <b>sprawna realizacja zlecenia</b> - ustalając stawkę z góry i dokonując płatności możesz przyspieszyć wykonanie
      usługi
    </Trans>,
    <Trans>
      <b>wygodna forma płatności</b> - wpłaty możesz dokonać przelewem online, kartą lub BLIK-iem
    </Trans>,
    <Trans>
      <b>wsparcie Fixly</b> przy rozwiązywaniu kwestii spornych
    </Trans>,
  ],
};

export const PayWithFixlyFeatures: FC<Props> = (props) => {
  const translations = props.features || featureMessages.provider;

  const { onButtonClicked, buttonTitle, title, description, className, titleIcon, withAnalytics } = props;
  const location = useLocation();

  useEffect(() => {
    if (withAnalytics) {
      const relEntityType = new URLSearchParams(location.search).get('rel_entity_type');
      analytics.trackEvent('pwf_reg_start', {
        entry_point: relEntityType || 'settings',
      });
    }
  }, [withAnalytics, location.search]);

  const titleCN = c('pwfFeatures__title', {
    'pwfFeatures__title_icon': titleIcon,
  });

  return (
    <div className={c('payWithFixly', className)}>
      <div className={titleCN}>
        <Heading4>{title}</Heading4>
      </div>
      <TextP3 className="pwfFeatures__description">{description}</TextP3>
      <TextP3 bold className="pwfFeatures__listTitle">
        {props.listTitle || (
          <>
            <Trans>Aktywuj Płatności Fixly.</Trans> <Trans>Oto, co zyskujesz:</Trans>
          </>
        )}
      </TextP3>
      <CheckboxIconList className="pwfFeatures__list">
        {translations.map((item, index) => (
          <CheckboxIconListItem key={`item-${index}`}>{item}</CheckboxIconListItem>
        ))}
      </CheckboxIconList>
      <a href={props.helpDeskUrl} target="_blank" rel="noreferrer" className="pwfFeatures__faqLink">
        <Trans>Dowiedz się więcej o Płatnościach Fixly</Trans>
      </a>
      {buttonTitle && (
        <Button type="button" width="full" onClick={onButtonClicked}>
          {buttonTitle}
        </Button>
      )}
    </div>
  );
};
