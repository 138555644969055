import { Trans } from '@lingui/macro';
import React from 'react';

import { TextP2 } from 'uikit/Text';

import './ProviderFeedbacksEmpty.scss';
import { ReactComponent as SVGEmpty } from './assets/empty.svg';

const ProviderFeedbacksEmpty = () => {
  return (
    <div className="providerFeedbacksEmpty">
      <SVGEmpty aria-hidden />
      <TextP2 className="providerFeedbacksEmpty__title" bold>
        <Trans>Brak opinii.</Trans>
      </TextP2>
    </div>
  );
};

export default ProviderFeedbacksEmpty;
