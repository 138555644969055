import classnames from 'classnames';
import React, { ReactNode } from 'react';

import './Tooltip.scss';

export type Tooltip$Props = {
  children: ReactNode;
  className?: string;
  contentClassName?: string;
};

const Tooltip = ({ children, className, contentClassName }: Tooltip$Props) => (
  <div role="tooltip" className={classnames('fixlyTooltip', className)}>
    <div className={classnames('fixlyTooltip__content', contentClassName)}>{children}</div>
  </div>
);
export default Tooltip;
