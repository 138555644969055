import { Trans } from '@lingui/macro';
import c from 'classnames';
import reactHTML from 'html-react-parser';
import React, { ReactNode, FC } from 'react';

import './ProviderBadges.scss';
import { ReactComponent as CardIcon } from './img/cardIcon.svg';
import { ReactComponent as CompanyIcon } from './img/companyIcon.svg';
import { ReactComponent as DateRegisteredIcon } from './img/dateRegisteredIcon.svg';
import { ReactComponent as ExperienceIcon } from './img/experienceIcon.svg';
import { ReactComponent as IkeaIcon } from './img/furnitureIcon.svg';
import { ReactComponent as CompanyVerification } from './img/gusIcon.svg';
import { ReactComponent as InsuranceIcon } from './img/insuranceIcon.svg';
import { ReactComponent as PersonIcon } from './img/personIcon.svg';
import { ReactComponent as ResponseTimeIcon } from './img/responseTimeIcon.svg';

export enum ProfileBadgeId {
  YearsOfExperience = 'YearsOfExperience',
  VerifiedCompany = 'VerifiedCompany',
  TimeOnFixly = 'TimeOnFixly',
  ResponseTime = 'ResponseTime',
  IkeaExperience = 'IkeaExperience',
  PayWithFixly = 'PayWithFixly',
  Company = 'Company',
  PrivatePerson = 'PrivatePerson',
  Insurance = 'Insurance',
}

export interface ProfileBadge {
  id: ProfileBadgeId;
  title: string;
  tooltip?: string;
  actionUrl?: string;
  actionTitle?: string;
}

export type Props = {
  smallMode?: boolean;
  className?: string;
  labelClassName?: string;
  boldLabels?: boolean;
  badges: ProfileBadge[];
};

const getIconByBadgeId = (badgeId: ProfileBadgeId): ReactNode => {
  const className = 'providerBadges__badgeIcon';

  switch (badgeId) {
    case ProfileBadgeId.YearsOfExperience:
      return <ExperienceIcon className={className} aria-hidden />;

    case ProfileBadgeId.VerifiedCompany:
      return <CompanyVerification className={className} aria-hidden />;

    case ProfileBadgeId.TimeOnFixly:
      return <DateRegisteredIcon className={className} aria-hidden />;

    case ProfileBadgeId.ResponseTime:
      return <ResponseTimeIcon className={className} aria-hidden />;

    case ProfileBadgeId.IkeaExperience:
      return <IkeaIcon className={className} aria-hidden />;

    case ProfileBadgeId.PayWithFixly:
      return <CardIcon className={className} aria-hidden />;

    case ProfileBadgeId.Company:
      return <CompanyIcon className={className} aria-hidden />;

    case ProfileBadgeId.PrivatePerson:
      return <PersonIcon className={className} aria-hidden />;

    case ProfileBadgeId.Insurance:
      return <InsuranceIcon className={className} aria-hidden></InsuranceIcon>;

    default:
      return null;
  }
};

const ProviderBadges: FC<Props> = (props) => {
  const { smallMode, boldLabels, badges } = props;
  const hostCN = c('providerBadges', { 'providerBadges_smallMode': smallMode }, props.className);
  const labelCN = c(
    'providerBadges__badgeText',
    { 'providerBadges__badgeText_bold': boldLabels },
    props.labelClassName
  );

  return (
    <div className={hostCN} data-testid="providerBadgesWrapper">
      {badges.map((badge) => (
        <div className="providerBadges__badge" key={badge.id} data-testid={`providerBadge-${badge.id}`}>
          {getIconByBadgeId(badge.id)}
          <span className={labelCN}>{badge.title}</span>
          {badge.actionUrl && (
            <a href={badge.actionUrl} target="_blank" rel="noreferrer">
              {badge.actionTitle || <Trans>Szczegóły</Trans>}
            </a>
          )}

          {!!badge.tooltip && (
            <div className="providerBadges__tooltip">
              <div className="providerBadges__tooltipContentWrapper">{reactHTML(badge.tooltip)}</div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ProviderBadges;
