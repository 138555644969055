import React, { FC } from 'react';

import BreadcrumbList, { PathItem } from 'Components/SeoSchema/BreadcrumbList';
import { cityNameShortener } from 'common/helpers/helpers';
import config from 'config/config';
import routes from 'utils/routeTranslator';

type Props = {
  l2: {
    slug: string;
    name: string;
  };
  city: {
    name: string;
    slug: string;
  };
};

const SPPublicProfileBreadcrumbsSchema: FC<Props> = (props) => {
  const { l2, city } = props;
  const cityName = cityNameShortener(city.name) || city.name;

  const cityRoute = routes.get('front::categories.type', {
    slug: l2.slug,
    city: city.slug,
  });

  const items: PathItem[] = [
    {
      name: l2.name,
      url: `${config.SITE_URL}${routes.get('front::categories.type', { slug: l2.slug })}`,
    },
    {
      name: `${l2.name} ${cityName}`,
      url: `${config.SITE_URL}${cityRoute}`,
    },
    {
      name: `🥇 Najlepszy wykonawca`,
      url: '',
    },
  ];
  return <BreadcrumbList items={items} />;
};

export default SPPublicProfileBreadcrumbsSchema;
