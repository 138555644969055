import { QueryHookOptions, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { createContext } from 'react';

import { TProviderPublicProfileData } from 'pages/SPPublicProfile/SPPublicProfile.helpers';
import { TUserVas } from 'utils/graphql/providerWallet';

type AnyData = { [key: string]: any };

type ModalContext = {
  id: string | null;
  data: AnyData;
  slug: string | null;
  setId: (id: string | null) => void;
  setData: (data: AnyData) => void;
};

export const defaultValue: ModalContext = {
  id: null,
  data: {},
  slug: null,
  setId: () => {},
  setData: () => {},
};

export const ProviderProfileEditContext = createContext<ModalContext>(defaultValue);

export const isPublicProfileVasEnabled = (vases: ActiveUserVas[]): boolean => {
  return vases.some((vas) => {
    const isProfile = vas.vas?.type === 'contact_card';
    return isProfile && vas.isEnabled;
  });
};

type ActiveUserVas = {
  isEnabled: TUserVas['isEnabled'];
  vas: Pick<TUserVas['vas'], 'type' | 'id'>;
};

type ActiveVasesQueryResponse = {
  wallet: {
    activeVases: ActiveUserVas[];
  };
};

export const activeVasesQuery = gql`
  query ActiveVases {
    wallet {
      activeVases {
        isEnabled
        vas {
          id
          type
        }
      }
    }
  }
`;

export const useActiveVasesQuery = (options?: QueryHookOptions) => {
  return useQuery<ActiveVasesQueryResponse>(activeVasesQuery);
};

export type CompanyResponse = {
  me: {
    id: string;
    provider: {
      id: string;
      companyData: TProviderPublicProfileData['company'];
    };
  };
};

export const companyQuery = gql`
  query Company {
    me {
      id
      provider {
        id
        companyData {
          id
          name
          nip
          contactName
          address
          foundationDate
          verificationStatus
        }
      }
    }
  }
`;
