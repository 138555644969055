import { Trans, t } from '@lingui/macro';
import classnames from 'classnames';
import React, { FC, ReactNode, Fragment, useState, SyntheticEvent, useContext, ReactEventHandler } from 'react';

import { ProviderProfileEditContext } from 'Components/DashboardProviderProfile/DashboardProviderProfile.helpers';
import { Image } from 'common/Image/Image';
import { ProfileBadge } from 'common/ProviderBadges/ProviderBadges';
import RatingView from 'common/RatingView/RatingView';
import { ReactComponent as CameraIcon } from 'common/icons/camera.svg';
import { ReactComponent as CloseIcon } from 'common/icons/close.svg';
import { ReactComponent as ShareIcon } from 'common/icons/share.svg';
import { getCanonicalBySlug } from 'pages/SPPublicProfile/SPPublicProfile.helpers';
import { Button, FabButton } from 'uikit/Button';
import { Heading4 } from 'uikit/Heading';
import analytics from 'utils/analytics/analytics';

import ProfileEditButton from '../ProfileEditButton/ProfileEditButton';
import {
  getAddressText,
  getLastSeenText,
  isProviderOnline,
  ProviderInfoProps,
  getProfileShareText,
  isDesktopSafari,
} from './ProviderInfo.helpers';
import './ProviderInfo.scss';
import defaultCover from './assets/cover_placeholder.png';

const ProviderInfo: FC<ProviderInfoProps> = (props) => {
  const [isShareOpen, setIsShareOpen] = useState<boolean>(false);
  const { setId, setData } = useContext(ProviderProfileEditContext);
  const online = props.lastActivity && isProviderOnline(props.lastActivity);

  const toggleSharing = (event: SyntheticEvent): void => {
    const isShareSupported = Boolean(window.navigator.share);

    /**
     * Native share is disabled for desktop Safari
     * because it doesn't have "Copy link" option in context menu
     * see https://discussions.apple.com/thread/252923714
     */
    if (isShareSupported && !isDesktopSafari()) {
      event.preventDefault();
      window.navigator.share({
        url: getCanonicalBySlug(props.slug),
      });
    } else {
      setIsShareOpen(!isShareOpen);
    }
  };

  const coverUrl = props.coverUrl || defaultCover;

  const copyProfileToClipboard = () => {
    // Clipboard API requires HTTPS
    try {
      const text = getProfileShareText(props.slug);
      window.navigator.clipboard.writeText(text).finally(() => setIsShareOpen(false));
    } catch (error) {
      console.warn('Copying is not supported');
    }
  };

  const statusText = online
    ? t`Użytkownik jest online`
    : `Online ${props.lastActivity ? getLastSeenText(props.lastActivity) : ''}`;

  const statusCN = classnames('providerInfo__status', {
    'providerInfo__status_online': online,
    'providerInfo__status_offline': !online,
  });
  const hostCN = classnames('providerInfo', props.className);
  const shareCN = classnames('providerInfo__shareMenu', {
    providerInfo__shareMenu_open: isShareOpen,
  });

  const avatarCN = classnames('providerInfo__avatar', {
    providerInfo__avatar_interactive: props.editable,
  });

  const renderFeaturedLabel = (): ReactNode => {
    return (
      <span className="providerInfo__label providerInfo__label_cta">
        <Trans>Polecany!</Trans>
      </span>
    );
  };

  const renderExperience = (items: ProfileBadge[]): ReactNode => {
    return (
      <div className="providerInfo__row providerInfo__experience">
        {items.map((item, index) => {
          return (
            <Fragment key={`${item.id}-${index}`}>
              {index > 0 ? ' • ' : ''}{' '}
              <span>
                {item.title}{' '}
                {item.actionUrl && (
                  <a href={item.actionUrl} target="_blank" rel="noreferrer">
                    {item.actionTitle}
                  </a>
                )}
              </span>
            </Fragment>
          );
        })}
      </div>
    );
  };

  const onFileChange: ReactEventHandler<HTMLInputElement> = (event) => {
    if (event.currentTarget.files) {
      const file: File = event.currentTarget.files[0];
      setData({
        image: file,
      });
      const modalId = event.currentTarget.dataset.modal;
      setId(modalId || null);
      // Note: Chrome doesn't fire change event when user pick the same file second time
      // so we need to clear input value
      event.currentTarget.value = '';
    }
  };

  const onPurchaseClick: ReactEventHandler = () => {
    setId('wallet');
    analytics.trackEvent('sp_profile_purchase_button_click', {
      purchase_point: 'profile_cover',
    });
  };

  return (
    <div className={hostCN}>
      <div className="providerInfo__cover" style={{ backgroundImage: `url('${coverUrl}')` }}>
        {props.editable && props.premium && (
          <Button as="label" kind="secondary" className="providerInfo__coverUpload" icon={<CameraIcon />}>
            <input
              className="visually-hidden"
              type="file"
              accept=".jpg, .jpeg, .png"
              onChange={onFileChange}
              data-modal={'profile-cover'}
            />
            <Trans>Edytuj baner</Trans>
          </Button>
        )}
        {props.editable && !props.premium && (
          <Button
            kind="secondary"
            className="providerInfo__coverUpload"
            icon={<CameraIcon />}
            onClick={onPurchaseClick}
          >
            <Trans>Edytuj baner</Trans>
          </Button>
        )}
      </div>
      <div className="providerInfo__content">
        <label className={avatarCN}>
          <Image className="providerInfo__photo" src={props.avatarUrl} multiPixelRatios alt={props.companyName} />
          {props.editable && (
            <div className="providerInfo__upload">
              <CameraIcon aria-hidden="true" width={18} height={18}></CameraIcon>
              <span className="visually-hidden">
                <Trans>Ustaw zdjęcie profilowe</Trans>
              </span>
              <input
                className="visually-hidden"
                type="file"
                accept=".jpg, .jpeg, .png"
                onChange={onFileChange}
                onClick={() => analytics.trackEvent('sp_profile_photo_click')}
                data-modal={'avatar'}
              />
            </div>
          )}
        </label>
        <div className="providerInfo__share">
          <div className={shareCN}>
            <button
              className="providerInfo__shareMethod providerInfo__shareMethod_clipboard"
              type="button"
              onClick={copyProfileToClipboard}
            >
              <span className="visually-hidden">
                <Trans>Kopiuj link</Trans>
              </span>
            </button>
          </div>
          <FabButton
            kind="secondary"
            className="providerInfo__fab"
            icon={isShareOpen ? <CloseIcon /> : <ShareIcon />}
            onClick={toggleSharing}
          >
            <span className="visually-hidden">
              <Trans>Udostępnij profil</Trans>
            </span>
          </FabButton>
        </div>
        <Heading4 as="h1" className="providerInfo__title">
          {props.publicName} {props.featured && renderFeaturedLabel()}{' '}
          {props.editable && <ProfileEditButton id="section-info" />}
        </Heading4>
        <div className="providerInfo__row">
          <span className="providerInfo__address">{getAddressText(props.cityName, props.regionName)}</span>
          <span className={statusCN}>{statusText}</span>
        </div>
        {Boolean(props.experience.length) && renderExperience(props.experience)}
        {Boolean(props.rating) && (
          <div className="providerInfo__row">
            <RatingView
              feedbackCount={props.feedbacksCount || 0}
              feedbackClientsCount={props.feedbackClientsCount}
              rating={props.rating}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default ProviderInfo;
