import React, { ReactNode } from 'react';
import classnames from 'classnames';

import Error from '../Error/Error';

import './Radio.scss';

export type Radio$Props = {
  id: string;
  name: string;
  label: ReactNode;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  labelClassName?: string;
  wrapperClassName?: string;
  disabled?: boolean;
  error?: ReactNode;
};

export default function Radio(props: Radio$Props) {
  const { id, label, error, className, labelClassName, wrapperClassName, disabled, ...rest } = props;

  const wrapperCs = classnames('radio__wrap', wrapperClassName);
  const inputCs = classnames('radio', className);

  const labelCs = classnames(
    {
      'radio__label': true,
      'radio__label_disabled': disabled,
      'radio__label_error': error,
    },
    labelClassName
  );

  return (
    <div className={wrapperCs}>
      <input id={id} className={inputCs} type="radio" disabled={disabled} {...rest} />
      <label className={labelCs} htmlFor={id}>
        {label}
      </label>

      {error && <Error>{error}</Error>}
    </div>
  );
}
