import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useRouteMatch } from 'react-router-dom';

import defaultCover from 'Components/ProviderProfileView/ProviderInfo/assets/cover_placeholder.png';
import ProviderProfileView from 'Components/ProviderProfileView/ProviderProfileView';
import PageView from 'common/PageView/PageView';

import {
  useProviderPublicProfileQuery,
  getPublicProfileSiteTitle,
  getPublicProfileOgDescription,
  getCanonicalBySlug,
  getPrimarySpecialization,
} from './SPPublicProfile.helpers';
import './SPPublicProfile.scss';
import SPPublicProfileBreadcrumbsSchema from './SPPublicProfileBreadcrumbsSchema';
import SPPublicProfileBusinessSchema from './SPPublicProfileBusinessSchema';

const ProviderPublicProfile: FC = () => {
  const match = useRouteMatch<{ slug: string }>();
  const { data } = useProviderPublicProfileQuery({
    variables: {
      slug: decodeURIComponent(match.params.slug),
    },
  });

  if (!data || !data.profileBySlug) {
    return null;
  }
  const profile = data.profileBySlug;

  const { searchIndexing, seoDescription, publicName, feedbacksCount, city, slug, firstName, lastName } = profile;

  const pageTitle = getPublicProfileSiteTitle({
    publicName,
    feedbacksCount,
    city,
  });
  const canonicalUrl = getCanonicalBySlug(match.params.slug);

  const noIndex = !['index_auto', 'index_admin'].includes(searchIndexing);

  const primaryL2 = getPrimarySpecialization(profile);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta property="og:title" content={pageTitle} />
        <meta name="description" content={seoDescription} />
        <meta property="og:description" content={getPublicProfileOgDescription(profile) || seoDescription} />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:image" content={profile.coverUrl || defaultCover} />
        <meta property="og:type" content="profile" />
        <meta property="profile:first_name" content={firstName} />
        <meta property="profile:last_name" content={lastName} />
        {profile.slugAlias && <meta property="profile:username" content={profile.slugAlias} />}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={getPublicProfileOgDescription(profile) || seoDescription} />
        <meta name="twitter:image" content={profile.coverUrl || defaultCover} />
        <link rel="canonical" href={canonicalUrl} />
        {noIndex && <meta name="robots" content="noindex, follow" />}
        <meta http-equiv="Content-Language" content="pl" />
      </Helmet>
      <PageView pageLabel="provider_profile_page" />

      <ProviderProfileView profile={profile} className="spPublicProfile" />
      <SPPublicProfileBusinessSchema slug={slug} spProfile={profile} />
      {primaryL2 && city && <SPPublicProfileBreadcrumbsSchema l2={primaryL2} city={city} />}
    </>
  );
};

export default ProviderPublicProfile;
