import React, { FC, MouseEventHandler, ReactNode } from 'react';
import Gallery, { ReactImageGalleryItem } from 'react-image-gallery';

import Modal from '../Modal/Modal';
import './ImageGallery.scss';

type ImageGalleryItem = {
  url: string;
  title?: string;
};

type ImageGalleryProps = {
  images: ImageGalleryItem[];
  isOpen: boolean;
  defaultImageIndex?: number;
  onClose: () => void;
  onGalleryImageSlide?: () => void;
};

const ImageGallery: FC<ImageGalleryProps> = (props) => {
  const getGalleryItems = (images: ImageGalleryItem[]): ReactImageGalleryItem[] => {
    return images.map((image) => ({
      original: image.url,
      thumbnail: image.url,
      originalClass: 'imageGallery__slide',
      thumbnailClass: 'imageGallery__thumbnail',
      originalAlt: image.title || '',
      thumbnailAlt: image.title || '',
      description: image.title || '',
    }));
  };

  const renderThumbnailInner = (item: ReactImageGalleryItem): ReactNode => {
    return <img className="imageGallery__thumbImg" src={item.thumbnail} alt={item.thumbnailAlt} />;
  };

  const renderItem = (item: ReactImageGalleryItem): ReactNode => {
    return (
      <div className="imageGallery__slideInner">
        <img className="imageGallery__item" src={item.original} alt={item.originalAlt} />
        {item.description && renderDescription(item.description)}
      </div>
    );
  };

  const renderDescription = (description: string): ReactNode => {
    return <span className="imageGallery__description">{description}</span>;
  };

  const renderNavButton = (
    onClick: MouseEventHandler<HTMLElement>,
    modifierClass: string,
    label?: string
  ): ReactNode => {
    return (
      <button className={`imageGallery__nav ${modifierClass}`} onClick={onClick} aria-label={label} type="button">
        <span className="imageGallery__arrow" />
      </button>
    );
  };

  const renderLeftNav = (onClick: React.MouseEventHandler<HTMLElement>): ReactNode => {
    return renderNavButton(onClick, 'imageGallery__nav_left', 'Previous');
  };

  const renderRightNav = (onClick: React.MouseEventHandler<HTMLElement>): ReactNode => {
    return renderNavButton(onClick, 'imageGallery__nav_right', 'Next');
  };

  return (
    <Modal
      isOpen={props.isOpen}
      handleClose={props.onClose}
      isCloseButton={true}
      className="imageGallery"
      overlayClassName="imageGallery__overlay"
      modalClassModifier="imageGallery__modalContent"
    >
      <Gallery
        items={getGalleryItems(props.images)}
        showFullscreenButton={false}
        showPlayButton={false}
        autoPlay={false}
        startIndex={props.defaultImageIndex || 0}
        onSlide={props.onGalleryImageSlide}
        preventDefaultTouchmoveEvent
        renderThumbInner={renderThumbnailInner}
        renderItem={renderItem}
        renderLeftNav={renderLeftNav}
        renderRightNav={renderRightNav}
      />
    </Modal>
  );
};

export default ImageGallery;
