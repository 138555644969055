import { QueryHookOptions, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import config from 'config/config';

interface ProviderFeedbackReply {
  id: string;
  comment: string;
  status: 'waiting_for_moderation' | 'approved' | 'rejected';
  createdAt: string;
  updatedAt: string;
}

export interface PublicProfileFeedback {
  id: string;
  comment?: string;
  authorFirstName?: string;
  authorGender?: string;
  authorAvatarUrl?: string;
  categoryName?: string;
  cityName?: string;
  createdAt?: string;
  rating: number;
  files?: string[];
  seen?: boolean;
  isExternal: boolean;
  l4CategoryNames?: string[];
  l4Slug?: string;
  reply?: ProviderFeedbackReply;
}

interface ProviderFeedbacksResponse {
  providerFeedbacksBySlug: PublicProfileFeedback[];
  profileBySlug: {
    id: string;
    rating: number;
    firstName: string;
    lastName: string;
    companyName: string;
    avatarUrl: string;
    feedbacksCount: number;
    feedbackClientsCount: number;
    feedbackInfo: {
      ratePercentages: {
        rate: number;
        percentage: number;
      }[];
      topL4Categories: {
        id: string;
        name: string;
      }[];
    };
  };
}

export interface ProviderFeedbacksSearchInput {
  rating: number[];
  l4CategoryIds: string[];
  text: string;
}

interface ProviderFeedbacksVariables {
  slug: string;
  searchInput?: ProviderFeedbacksSearchInput;
  offset?: number;
  limit?: number;
}

const providerFeedbacksQuery = gql`
  query providerFeedbacks(
    $slug: String!
    $searchInput: ProviderFeedbackSearchInput
    $offset: Int = 0
    $limit: Int = 3
  ) {
    providerFeedbacksBySlug(slug: $slug, searchInput: $searchInput, offset: $offset, limit: $limit) {
      id
      comment
      authorFirstName
      authorGender
      authorAvatarUrl
      categoryName
      cityName
      createdAt
      rating
      files
      seen
      isExternal
      l4CategoryNames
      l4Slug
      reply {
        id
        comment
        status
        createdAt
        updatedAt
      }
    }
    profileBySlug(slug: $slug) {
      id
      firstName
      lastName
      companyName
      avatarUrl
      rating
      feedbacksCount
      feedbackClientsCount
      feedbackInfo {
        ratePercentages {
          rate
          percentage
        }
        topL4Categories {
          id
          name
        }
      }
    }
  }
`;

export const useProviderFeedbacksQuery = (
  options?: QueryHookOptions<ProviderFeedbacksResponse, ProviderFeedbacksVariables>
) => useQuery(providerFeedbacksQuery, options);

export const formatDate = (date: string): string => {
  return new Date(date).toLocaleDateString(config.LOCALE, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};
