import classnames from 'classnames';
import React, { FC, KeyboardEventHandler, ReactEventHandler, ReactNode, useEffect, useRef, useState } from 'react';

import './Collapsible.scss';
import { ReactComponent as ChevronIcon } from './chevron-down.svg';

type Props = {
  id?: string;
  className?: string;
  innerContainerClassName?: string;
  headingClassName?: string;
  open?: boolean;
  onChange?: (state: boolean) => void;
  summary?: ReactNode | ((state: boolean) => ReactNode);
};

type ContentState = 'collapsed' | 'expanded' | 'expanding' | 'collapsing';

let idCounter = 0;
const generateId = (): number => ++idCounter;

const Collapsible: FC<Props> = (props) => {
  const initialStyle = {
    height: props.open ? 'auto' : '0px',
    overflow: props.open ? 'visible' : 'hidden',
  };
  const [state, setState] = useState<ContentState>(props.open ? 'expanded' : 'collapsed');
  const contentRef = useRef<HTMLDivElement | null>(null);
  const bodyRef = useRef<HTMLDivElement | null>(null);
  const idRef = useRef<number>(generateId());

  const { onChange } = props;

  const toggle: ReactEventHandler = () => {
    if (state === 'collapsed' || state === 'collapsing') {
      expand();
    } else {
      collapse();
    }
  };

  const handleKeyPress: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (['Enter', ' ', 'Spacebar'].includes(event.key)) {
      event.preventDefault();
      toggle(event);
    }
  };

  const expand = (): void => {
    setState('expanding');
  };

  const collapse = (): void => {
    setState('collapsing');
  };

  useEffect(() => {
    const element = contentRef.current;
    const onEnd = () => {
      if (state === 'expanding') {
        setState('expanded');
        onChange && onChange(true);
      }
      if (state === 'collapsing') {
        setState('collapsed');
        onChange && onChange(false);
      }
      element?.removeEventListener('transitionend', onEnd);
    };

    element?.addEventListener('transitionend', onEnd);

    return () => element?.removeEventListener('transitionend', onEnd);
  }, [state, onChange]);

  useEffect(() => {
    if (!contentRef.current || !bodyRef.current) return;

    switch (state) {
      case 'collapsed':
        contentRef.current.style.height = '0px';
        contentRef.current.style.overflow = 'hidden';
        break;
      case 'expanding':
        const targetHeight = `${bodyRef.current.offsetHeight}px`;
        contentRef.current.style.height = targetHeight;
        contentRef.current.style.overflow = 'hidden';
        break;
      case 'expanded':
        contentRef.current.style.height = 'auto';
        contentRef.current.style.overflow = 'visible';
        break;
      case 'collapsing':
        const currentHeight = `${bodyRef.current.offsetHeight}px`;
        contentRef.current.style.height = currentHeight;
        contentRef.current?.getBoundingClientRect();
        contentRef.current.style.height = '0px';
        contentRef.current.style.overflow = 'hidden';
        break;
      default:
        throw new Error(`State ${state} is not supported`);
    }
  }, [state]);

  const ariaExpanded: boolean = state === 'expanded' || state === 'expanding';

  const hostCN = classnames('collapsible', props.className);
  const headingCN = classnames('collapsible__heading', props.headingClassName);
  const bodyCN = classnames('collapsible__body', props.innerContainerClassName);
  const indicatorCN = classnames('collapsible__indicator', {
    collapsible__indicator_expanded: ariaExpanded,
  });
  const { summary } = props;

  const contentId = `collapsible-content-${props.id || idRef.current}`;

  return (
    <div className={hostCN} id={props.id}>
      <div
        className={headingCN}
        role="button"
        onClick={toggle}
        onKeyPress={handleKeyPress}
        aria-expanded={ariaExpanded}
        aria-controls={contentId}
        tabIndex={0}
      >
        {typeof summary == 'function' ? summary(state === 'expanded' || state === 'expanding') : summary}{' '}
        <ChevronIcon className={indicatorCN} aria-hidden />
      </div>

      <div
        ref={contentRef}
        className="collapsible__content"
        style={initialStyle}
        role="region"
        id={contentId}
        tabIndex={state === 'collapsed' ? -1 : undefined}
      >
        <div className={bodyCN} ref={bodyRef}>
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default Collapsible;
