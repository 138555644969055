import gql from 'graphql-tag';

export interface TProviderFeedbacksResponse {
  profileBySlug: {
    id: string;
    firstName: string;
    lastName: string;
    companyName: string;
  };
}

export const providerFeedbacksQuery = gql`
  query providerFeedbacks($slug: String!) {
    profileBySlug(slug: $slug) {
      id
      firstName
      lastName
      companyName
    }
  }
`;
