import React, { FC, useEffect } from 'react';

import analytics from 'utils/analytics/analytics';

type Props = {
  onMount?: {
    eventName: string;
    options?: Record<string, any>;
  };
};

const AnalyticsProviderFC: FC<Props> = (props) => {
  const { onMount: { eventName, options = {} } = {} } = props;
  useEffect(() => {
    if (eventName) {
      analytics.trackEvent(eventName, options);
    }
  }, [eventName, options]);
  return <>{props.children}</>;
};

export default AnalyticsProviderFC;
