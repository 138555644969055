import classnames from 'classnames';
import React from 'react';

import ProviderBadge from 'common/ProviderBadge/ProviderBadge';
import StarIcon from 'common/icons/star_gold.svg';

import ProviderAvatar, { ProviderAvatar$Props } from '../ProviderAvatar';
import './ProviderAvatarWithBadge.scss';

export type ProviderAvatarWithBadge$Props = ProviderAvatar$Props & {
  featured?: boolean;
  newcomer?: boolean;
  rated?: boolean;
  avatarClassName?: string;
  badgeClassName?: string;
  starClassName?: string;
};

const ProviderAvatarWithBadge: React.FC<ProviderAvatarWithBadge$Props> = ({
  src,
  size,
  avatarClassName,
  rated,
  starClassName,
  featured,
  newcomer,
  badgeClassName,
}) => (
  <div className="providerAvatarWithBadge">
    <ProviderAvatar src={src} size={size} className={avatarClassName} />

    {rated && (
      <img
        src={StarIcon}
        className={classnames('providerAvatarWithBadge__ratedStar', starClassName)}
        aria-hidden
        alt="star"
      />
    )}

    {(featured || newcomer) && (
      <div className="providerAvatarWithBadge__badgeWrapper">
        <ProviderBadge
          newcomer={newcomer}
          featured={featured}
          className={classnames('providerAvatarWithBadge__badge', badgeClassName)}
        />
      </div>
    )}
  </div>
);

export default ProviderAvatarWithBadge;
