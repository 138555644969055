import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export type QueryProfile = {
  providerProfileFlags: {
    showPayWithFixly?: boolean;
  };
};

export const useProfileQuery = () => {
  const { data: providerSettings } = useQuery<QueryProfile>(
    gql`
      query {
        providerProfileFlags {
          showPayWithFixly
        }
      }
    `,
    {}
  );
  return providerSettings?.providerProfileFlags.showPayWithFixly;
};
