import { Trans, t } from '@lingui/macro';
import classnames from 'classnames';
import React, { FC } from 'react';

import { ServicePrice } from 'pages/SPPublicProfile/SPPublicProfile.helpers';
import Collapsible from 'uikit/Collapsible/Collapsible';
import { TextP3 } from 'uikit/Text';

import ProfileEditButton from '../ProfileEditButton/ProfileEditButton';
import { getServicePriceBySpecialization } from '../ProfileEditModals/PricesEditModal/PricesEditModal.helpers';
import { ProviderSectionHeader } from '../ProviderSectionHeader/ProviderSectionHeader';
import './ProviderPrices.scss';

interface SpecializationName {
  id: string;
  name: string;
}

type Props = {
  id?: string;
  className?: string;
  slug: string;
  prices?: ServicePrice[];
  services: SpecializationName[];
  editable?: boolean;
};

type ServicePricesSection = {
  title: string;
  open: boolean;
  items: ServicePrice['items'];
};

const ProviderPrices: FC<Props> = (props) => {
  const sections: ServicePricesSection[] = props.services.map((specialization, index) => {
    const items = getServicePriceBySpecialization(specialization.id, props.prices)?.items || [];
    return {
      title: specialization.name,
      open: index === 0 && items.length > 0,
      items,
    };
  });

  const hostCN = classnames('providerPrices', props.className);
  return (
    <section className={hostCN} id={props.id}>
      <ProviderSectionHeader
        title={t`Cennik`}
        editButton={props.editable && <ProfileEditButton id="section-prices" />}
      />
      <div>
        {sections.map((section, index) => (
          <Collapsible
            key={index}
            open={section.open}
            summary={<h3 className="providerPrices__subtitle">{section.title}</h3>}
            headingClassName="providerPrices__heading"
          >
            <ul className="providerPrices__list">
              {section.items?.map((item, index) => (
                <li className="providerPrices__item" key={index}>
                  <span className="providerPrices__entry">
                    <span className="providerPrices__name">{item.serviceName}</span>
                    <span className="providerPrices__value">{item.servicePrice}</span>
                  </span>
                </li>
              ))}
              {(!section.items || !section.items.length) && (
                <TextP3 className="providerPrices__empty">
                  <Trans>Brak cennika</Trans>
                </TextP3>
              )}
            </ul>
          </Collapsible>
        ))}
      </div>
    </section>
  );
};

export default ProviderPrices;
