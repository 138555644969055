import c from 'classnames';
import React, { ReactNode } from 'react';

import './CheckboxIconList.scss';

interface Props {
  className?: string;
  children: ReactNode;
}

const CheckboxIconList: React.FC<Props> = ({ className, children }) => {
  return <ul className={c('checkboxIconList', className)}>{children}</ul>;
};

const CheckboxIconListItem: React.FC<Props> = ({ className, children }) => {
  return <li className={c('checkboxIconListItem', className)}>{children}</li>;
};

export { CheckboxIconList, CheckboxIconListItem };
