import { Trans } from '@lingui/macro';
import c from 'classnames';
import React, { useState, FC } from 'react';

import './ShadowText.scss';

type Props = {
  text: string;
  hideByDefault: boolean;
  kind?: 'email' | 'phone' | 'url';
  isInline?: boolean;
  className?: string;
  onReveal?: () => void;
  revealFn?: () => Promise<string>;
};

const protocolRegex = /^http(s)?:\/\/.+$/g;

const replaceText = (text: string, kind: Props['kind']): string => {
  switch (kind) {
    case 'email': {
      return `${text.substr(0, 4).replace('@', '')}***@****`;
    }
    case 'phone': {
      return `${text.substr(0, 3)} XXX XXX XXX`;
    }
    case 'url': {
      const urlAfterDomain = text.split('.').reverse()[0];
      const maskSuffix = urlAfterDomain.length > 4 ? '***' : '';
      return `******.${urlAfterDomain.substr(0, 4)}${maskSuffix}`;
    }
    default: {
      return `********`;
    }
  }
};

const DisplayComponent: FC<{ kind: Props['kind']; text: string }> = ({ kind, text }) => {
  switch (kind) {
    case 'email': {
      return (
        <a href={`mailto:${text}`} className="shadowText__link" target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      );
    }
    case 'phone': {
      return (
        <a href={`tel:${text}`} className="shadowText__link">
          {text}
        </a>
      );
    }
    case 'url': {
      const url = text.match(protocolRegex) ? text : `https://${text}`;
      return (
        <a href={url} className="shadowText__link" target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      );
    }
    default: {
      return <span>{text}</span>;
    }
  }
};

const ShadowText: FC<Props> = (props) => {
  const { hideByDefault, text, isInline, className, kind, onReveal, revealFn } = props;
  const [isTextHidden, setIsTextHidden] = useState(hideByDefault);
  const [pending, setPending] = useState<boolean>(false);
  const [value, setValue] = useState<string>(text);

  const revealText = (): void => {
    const onComplete = () => {
      setIsTextHidden(false);
      onReveal && onReveal();
    };

    if (revealFn) {
      setPending(true);
      revealFn()
        .then((revealed) => setValue(revealed))
        .then(onComplete)
        .finally(() => setPending(false));
    } else {
      onComplete();
    }
  };

  return (
    <span
      className={c('shadowText', className, {
        'shadowText_shown': !isTextHidden,
        'shadowText_inline': isInline,
        [`shadowText_${kind}`]: kind,
      })}
    >
      {isTextHidden ? (
        <button type="button" className="shadowText__show" onClick={revealText} disabled={pending}>
          <span className="shadowText__text">{revealFn ? value : replaceText(value, kind)}</span>{' '}
          <span className="shadowText__action">
            <Trans>Pokaż</Trans>
          </span>
        </button>
      ) : (
        <DisplayComponent text={value} kind={kind} />
      )}
    </span>
  );
};

export default ShadowText;
