import { t } from '@lingui/macro';
import gql from 'graphql-tag';

import { ProfileBadge, ProfileBadgeId } from 'common/ProviderBadges/ProviderBadges';
import { TProviderPublicProfileData } from 'pages/SPPublicProfile/SPPublicProfile.helpers';

import { ServiceNode } from './ProviderServices/ProviderServices';

export interface ProviderProfileViewProps {
  className?: string;
  profile: TProviderPublicProfileData;
  editable?: boolean;
  premium?: boolean;
}

export const buildServicesTree = (specializations: TProviderPublicProfileData['specializations']): ServiceNode[] => {
  if (!specializations) return [];

  return specializations
    .map((l2) => {
      const children = l2.servicesL4.filter((s) => s.checked);
      return {
        id: l2.id,
        name: l2.name,
        slug: l2.slug,
        children: children.length ? children : undefined,
      };
    })
    .filter((l2) => Boolean(l2.children));
};

export const getProviderProfileTitle = (firstName: string, lastName: string, companyName?: string): string => {
  return companyName ? companyName : `${firstName} ${lastName}`;
};

const experienceBadges: ProfileBadgeId[] = [
  ProfileBadgeId.TimeOnFixly,
  ProfileBadgeId.YearsOfExperience,
  ProfileBadgeId.Company,
  ProfileBadgeId.PrivatePerson,
];

export const buildCompanyBadge = (linkEnabled?: boolean): ProfileBadge => {
  const badge: ProfileBadge = {
    id: ProfileBadgeId.Company,
    title: t`Firma`,
  };

  if (linkEnabled) {
    badge.actionUrl = 'https://pomoc.fixly.pl/hc/pl/articles/10358131177746';
    badge.actionTitle = t`Szczegóły`;
  }

  return badge;
};

export const getExperienceBadges = (badges: ProfileBadge[]) => {
  return badges.filter((b) => experienceBadges.includes(b.id));
};

export type ProviderNearbyCitiesResponse = {
  providerNearbyCities: Array<{
    id: string;
    slug: string;
    name: string;
  }>;
};

export const providerNearbyCitiesQuery = gql`
  query ProviderNearbyCities($slug: String!) {
    providerNearbyCities(userSlug: $slug) {
      id
      slug
      name
    }
  }
`;
