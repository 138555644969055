import gql from 'graphql-tag';

export type PhoneResponse = {
  me: {
    id: string;
    phone?: string;
  };
};

export const currentUserPhoneQuery = gql`
  query CurrentUserPhone {
    me {
      id
      phone
    }
  }
`;
