import React, { Component, MouseEvent, ReactEventHandler, ReactNode } from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import debounce from 'lodash.debounce';

import ImageGallery from '../ImageGallery/ImageGallery';

import platformDetector from '../helpers/platformDetector';

import './ImageSlider.scss';

type ImageSliderItem = {
  url: string;
  title?: string;
};

type ImageSliderProps = {
  images: ImageSliderItem[];
  maxVisibleImages: number;
  replaceEmpty?: boolean;
  clickable?: boolean;
  animationDuration?: number;
  minImageMargin?: number;
  staticPreview?: boolean;
  className?: string;
  imageClassName?: string;
  buttonClassName?: string;
  onImageClick?: (index: number, url: string) => void;
  onGalleryOpen?: () => void;
  onGalleryImageSlide?: () => void;
};

type ImageSliderState = {
  spaceBetweenImages: number;
  buttonsHidden: boolean;
  isGalleryOpen: boolean;
  defaultImageIndex: number;
};

// eslint-disable-next-line
class ImageSlider extends Component<ImageSliderProps, ImageSliderState> {
  static propTypes = {
    maxVisibleImages: propTypes.number.isRequired,
  };

  static defaultProps = {
    animationDuration: 500,
    minImageMargin: 5,
    className: '',
    imageClassName: '',
  };

  state = {
    spaceBetweenImages: 0,
    buttonsHidden: false,
    isGalleryOpen: false,
    defaultImageIndex: 0,
  };

  isMobile = Boolean(platformDetector.isAnyMobile());

  imageWrapperRef: HTMLDivElement | null = null;
  imageWrapperWidth = 0;
  firstImageRef: HTMLButtonElement | HTMLDivElement | null = null;
  firstImageWidth = 0;
  trunkRef: HTMLDivElement | null = null;

  numberOfImagesFit = 2;
  isWindowPresent: boolean = typeof window !== 'undefined';
  isAnimating = false;
  isMoving = false;
  isDragable = false;
  canFireImageClickEvent = true;
  animationStartTime: number = Date.now();
  animationEndTime: number = Date.now();

  nextTrunkTranslateX = 0;
  currentTrunkTranslateX = 0;

  mouseDownXPosition = 0;
  mouseMoveXPosition = 0;

  componentDidMount() {
    this.updateSizes();
    this.arrange();

    if (this.isWindowPresent) {
      window.addEventListener('resize', this.rearrange, { passive: true });
      window.addEventListener('mouseup', this.stopDrag);
      window.addEventListener('mouseleave', this.stopDrag);
      /* @ts-expect-error type mismatch */
      window.addEventListener('mousemove', this.dragTrunk);
    }
  }

  componentWillUnmount() {
    this.imageWrapperRef = null;

    if (this.isWindowPresent) {
      window.removeEventListener('resize', this.rearrange);
      window.removeEventListener('mouseup', this.stopDrag);
      window.removeEventListener('mouseleave', this.stopDrag);
      /* @ts-expect-error type mismatch */
      window.removeEventListener('mousemove', this.dragTrunk);
    }
  }

  openGalleryModal = (defaultImageIndex: number): void => {
    this.setState(
      () => ({
        isGalleryOpen: true,
        defaultImageIndex,
      }),
      this.props.onGalleryOpen
    );
  };

  closeGalleryModal = () => {
    this.setState(() => ({ isGalleryOpen: false }));
  };

  rearrange = debounce(() => {
    if (!this.props.staticPreview) {
      this.updateSizes();
      this.arrange();
      this.bounceToNearestImage();
    }
  }, 300);

  updateSizes = (): void => {
    if (!this.firstImageRef || !this.imageWrapperRef || this.isMobile) {
      return;
    }

    this.imageWrapperWidth = this.imageWrapperRef.getBoundingClientRect().width;
    // NOTE: next line just fixes flow error
    this.firstImageWidth = this.firstImageRef ? this.firstImageRef.getBoundingClientRect().width : 0;
  };

  arrange = (): void => {
    if (!this.firstImageRef || !this.imageWrapperRef || this.isMobile || this.props.staticPreview === true) {
      return;
    }

    const { minImageMargin, maxVisibleImages, images } = this.props;
    /* @ts-expect-error type mismatch */
    const possibleNumberOfVisibleImages = Math.floor(this.imageWrapperWidth / (this.firstImageWidth + minImageMargin));

    this.numberOfImagesFit =
      images.length <= possibleNumberOfVisibleImages && images.length <= maxVisibleImages
        ? possibleNumberOfVisibleImages
        : Math.min(possibleNumberOfVisibleImages, maxVisibleImages);

    // eslint-disable-next-line no-mixed-operators
    const nextMarginValue =
      (this.imageWrapperWidth - this.numberOfImagesFit * this.firstImageWidth) / (this.numberOfImagesFit - 1);

    this.setState(() => ({
      spaceBetweenImages: nextMarginValue,
      buttonsHidden: images.length <= this.numberOfImagesFit,
    }));
  };

  startDrag = (e: MouseEvent) => {
    if (this.isMobile || this.props.staticPreview === true) {
      return;
    }
    e.preventDefault();
    this.isDragable = true;
    this.mouseDownXPosition = e.clientX;
  };

  stopDrag = () => {
    this.isDragable = false;
  };

  dragTrunk = (e: MouseEvent) => {
    if (!this.isDragable) {
      return;
    }
    e.preventDefault();
    this.mouseMoveXPosition = e.clientX;
    this.canFireImageClickEvent = false;

    if (!this.isMoving && this.isWindowPresent) {
      window.requestAnimationFrame(this.moveTrunkInstantly);
    }
  };

  scroll = (e: MouseEvent<HTMLButtonElement>): void => {
    if (!this.trunkRef) {
      return;
    }
    const { direction } = e.currentTarget.dataset;
    const { spaceBetweenImages } = this.state;
    const imagesCount = this.props.images.length;
    const sectionWidth = this.firstImageWidth + spaceBetweenImages;
    // eslint-disable-next-line no-mixed-operators
    const rightEdgePosition = imagesCount * -sectionWidth + this.imageWrapperWidth;
    let newTrunkTranslateX = 0;
    this.animationStartTime = Date.now();

    if (direction === 'right') {
      newTrunkTranslateX = this.nextTrunkTranslateX - sectionWidth;
    } else {
      newTrunkTranslateX = this.nextTrunkTranslateX + sectionWidth;
    }

    if (Number(newTrunkTranslateX.toFixed(5)) > 0) {
      this.shake('left');
      return;
    } else if (newTrunkTranslateX < rightEdgePosition) {
      this.shake('right');
      return;
    }

    this.setTranslateValue(newTrunkTranslateX);
    this.nextTrunkTranslateX = newTrunkTranslateX;

    if (!this.isAnimating && this.isWindowPresent) {
      window.requestAnimationFrame(this.animate);
    }
  };

  bounceToNearestImage = () => {
    const { spaceBetweenImages } = this.state;
    const imagesCount = this.props.images.length;
    // eslint-disable-next-line no-mixed-operators
    const sectionWidth = this.firstImageWidth + spaceBetweenImages;
    // eslint-disable-next-line no-mixed-operators
    const rightEdgePosition = imagesCount * -sectionWidth + this.imageWrapperWidth + spaceBetweenImages;

    if (this.currentTrunkTranslateX > 0 || imagesCount <= this.numberOfImagesFit) {
      this.nextTrunkTranslateX = 0;
      this.startAnimation();
      return;
    } else if (this.currentTrunkTranslateX < rightEdgePosition) {
      this.nextTrunkTranslateX = rightEdgePosition;
      this.startAnimation();
      return;
    }

    const scrolledSections = Math.abs(this.currentTrunkTranslateX) / sectionWidth;
    const sectionProgress = scrolledSections % 1;
    const progressOnSingleImage = Math.min(
      // eslint-disable-next-line no-mixed-operators
      (sectionWidth * sectionProgress) / this.firstImageWidth,
      1
    );

    this.nextTrunkTranslateX =
      progressOnSingleImage > 0.5
        ? /* @ts-expect-error type mismatch */
          (parseInt(scrolledSections, 10) + 1) * sectionWidth * -1
        : /* @ts-expect-error type mismatch */
          parseInt(scrolledSections, 10) * sectionWidth * -1;

    this.startAnimation();
  };

  setTranslateValue = (value: number): void => {
    if (!this.trunkRef) {
      return;
    }
    this.trunkRef.style.transform = `translateX(${value}px)`;
  };

  // Easing function
  easeOutQuad = (t: number): number => t * (2 - t);

  startAnimation = () => {
    this.animationStartTime = Date.now();
    window.requestAnimationFrame(this.animate);
  };

  shake = (direction: 'left' | 'right'): void => {
    const progress = this.getProgress(200);
    let nextTrunkTranslateX = 0;
    const shakeWidth = 60;

    if (direction === 'left') {
      nextTrunkTranslateX = progress < 0.5 ? shakeWidth * 2 * progress : shakeWidth * 2 * (1 - progress);
    } else if (direction === 'right') {
      nextTrunkTranslateX =
        progress < 0.5
          ? this.currentTrunkTranslateX - shakeWidth * 2 * progress
          : this.currentTrunkTranslateX - shakeWidth * 2 * (1 - progress);
    }

    this.setTranslateValue(nextTrunkTranslateX);
    if (progress < 1) {
      window.requestAnimationFrame(() => this.shake(direction));
    }
  };

  animate = () => {
    if (this.currentTrunkTranslateX === this.nextTrunkTranslateX) {
      return;
    }
    this.isAnimating = true;

    const progress = this.getProgress();
    const diff = this.nextTrunkTranslateX - this.currentTrunkTranslateX;
    this.currentTrunkTranslateX += this.easeOutQuad(progress) * diff;

    if (1 - Number(progress.toFixed(5)) === 0) {
      this.currentTrunkTranslateX = this.nextTrunkTranslateX;
    }

    this.setTranslateValue(this.currentTrunkTranslateX);

    if (this.currentTrunkTranslateX !== this.nextTrunkTranslateX) {
      window.requestAnimationFrame(this.animate);
    } else {
      this.isAnimating = false;
    }
  };

  getProgress = (duration?: number): number =>
    // $FlowFixMe
    /* @ts-expect-error type mismatch */
    Math.min((Date.now() - this.animationStartTime) / (duration || this.props.animationDuration), 1);

  moveTrunkInstantly = () => {
    this.isMoving = true;
    // eslint-disable-next-line no-mixed-operators
    this.setTranslateValue(this.currentTrunkTranslateX + this.mouseMoveXPosition - this.mouseDownXPosition);

    if (this.isDragable) {
      window.requestAnimationFrame(this.moveTrunkInstantly);
    } else {
      this.isMoving = false;
      this.currentTrunkTranslateX -= this.mouseDownXPosition - this.mouseMoveXPosition;
      this.bounceToNearestImage();
      this.canFireImageClickEvent = true;
    }
  };

  getButtonClassName = (buttonType: 'left' | 'right'): string =>
    classnames({
      [`imageSlider__button imageSlider__button_${buttonType}`]: true,
      'imageSlider__button_hidden': this.state.buttonsHidden,
      [this.props.buttonClassName || '']: typeof this.props.buttonClassName === 'string',
      [`${this.props.buttonClassName || ''}_${buttonType}`]: typeof this.props.buttonClassName === 'string',
    });

  getImageClassName = (isClickable = false): string =>
    classnames({
      [isClickable ? 'imageSlider__clickableWrapper' : 'imageSlider__image']: true,
      [this.props.imageClassName || '']: typeof this.props.imageClassName === 'string',
    });

  getImageMarginValue = (imageUrl?: string): {} | { marginRight: string } =>
    this.isMobile || this.props.images.length < this.numberOfImagesFit || this.props.staticPreview === true
      ? { backgroundImage: imageUrl ? `url(${imageUrl})` : '' }
      : {
          marginRight: `${this.state.spaceBetweenImages}px`,
          backgroundImage: imageUrl ? `url(${imageUrl})` : '',
        };

  handleClickOnImage: ReactEventHandler<HTMLButtonElement> = (e): void => {
    if (!this.canFireImageClickEvent) {
      return;
    }

    const imageIndex = Number(e.currentTarget.dataset.imageIndex);
    const { imageUrl } = e.currentTarget.dataset;

    if (typeof this.props.onImageClick === 'function') {
      /* @ts-expect-error type mismatch */
      this.props.onImageClick(imageIndex, imageUrl);
    } else {
      this.openGalleryModal(imageIndex);
    }
  };

  /* eslint-disable react/no-array-index-key */
  renderImages(images: ImageSliderItem[]): ReactNode {
    return images.map((image, index, arr) =>
      typeof this.props.onImageClick === 'function' || (this.props.clickable === true && !!image.url) ? (
        <button
          type="button"
          className={this.getImageClassName(true)}
          style={this.getImageMarginValue()}
          ref={(imgEl) => {
            if (index === 0) {
              this.firstImageRef = imgEl;
            }
          }}
          key={index}
          data-image-index={index}
          data-image-url={image.url}
          onClick={this.handleClickOnImage}
        >
          <div
            className="imageSlider__image imageSlider__image_withWrapper"
            style={{ backgroundImage: `url(${image.url})` }}
          />
          {this.props.staticPreview === true && index === arr.length - 1 && arr.length < this.props.images.length && (
            <div className="imageSlider__lastImagePreview">+{this.props.images.length - arr.length}</div>
          )}
        </button>
      ) : (
        <div
          key={index}
          className={this.getImageClassName()}
          style={this.getImageMarginValue(image.url)}
          ref={(imgEl) => {
            if (index === 0) {
              this.firstImageRef = imgEl;
            }
          }}
        />
      )
    );
  }
  /* eslint-enable react/no-array-index-key */

  renderButtons() {
    return this.props.staticPreview ? null : (
      <>
        <button
          type="button"
          aria-label="Slide left"
          className={this.getButtonClassName('left')}
          data-direction="left"
          onClick={this.scroll}
        />

        <button
          type="button"
          aria-label="Slide right"
          className={this.getButtonClassName('right')}
          data-direction="right"
          onClick={this.scroll}
        />
      </>
    );
  }

  render() {
    const { replaceEmpty, images, className, staticPreview, maxVisibleImages, onGalleryImageSlide } = this.props;
    const { isGalleryOpen, defaultImageIndex } = this.state;

    const sliderCN = classnames({
      'imageSlider': true,
      [className || '']: Boolean(className),
    });

    const imagesToRender = staticPreview === true ? images.slice(0, maxVisibleImages) : images;

    const dummyImage = { url: '', title: '' };
    const dummyPlaceholders = new Array(maxVisibleImages || 5)
      .join('_')
      .split('_')
      .map((i, key) => imagesToRender[key] || dummyImage);

    return (
      <section className={sliderCN}>
        <div
          className="imageSlider__imageWrapper"
          ref={(el) => {
            this.imageWrapperRef = el;
          }}
          onMouseDown={this.startDrag}
        >
          <div
            className="imageSlider__imageWrapperTrunk"
            ref={(el) => {
              this.trunkRef = el;
            }}
          >
            {this.renderImages(replaceEmpty ? dummyPlaceholders : imagesToRender)}
          </div>
        </div>

        {this.renderButtons()}

        <ImageGallery
          images={images}
          isOpen={isGalleryOpen}
          defaultImageIndex={defaultImageIndex}
          onClose={this.closeGalleryModal}
          onGalleryImageSlide={onGalleryImageSlide}
        />
      </section>
    );
  }
}

export default ImageSlider;
