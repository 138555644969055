import { t } from '@lingui/macro';
import React, { FC } from 'react';
import { useHistory } from 'react-router';

import VerificationStatusPanel, {
  VerificationStatus,
} from 'Components/VerificationStatusPanel/VerificationStatusPanel';
import { ReactComponent as InsuranceBadge } from 'common/badges/insurance.svg';
import config from 'config/config';
import analytics from 'utils/analytics/analytics';
import routes from 'utils/routeTranslator';

type Props = {
  className?: string;
  editable?: boolean;
  title?: string;
  active?: boolean;
};

const ProviderInsurance: FC<Props> = (props) => {
  const defaultTitle = t`Ubezpieczenie`;
  const title = props.title || defaultTitle;
  const warning = !props.active ? t`nieaktywne` : undefined;
  const history = useHistory();

  const onAction = () => {
    if (props.editable) {
      analytics.trackEvent('sp_insurance_details');
      history.push(routes.get('provider::settings.insurance'));
    } else {
      window.open(config.FIX_HELPDESK_INSURANCE_EXPLANATION_FOR_CUSTOMERS_URL, '_blank', 'noopener, noreferrer');
    }
  };

  return (
    <VerificationStatusPanel
      badge={<InsuranceBadge aria-hidden />}
      title={title}
      warning={warning}
      status={props.active ? VerificationStatus.verified : VerificationStatus.default}
      className={props.className}
      action={t`Szczegóły`}
      onAction={onAction}
    ></VerificationStatusPanel>
  );
};

export default ProviderInsurance;
