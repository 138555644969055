import { useQuery } from '@apollo/react-hooks';
import { t, Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { animateScroll } from 'react-scroll';

import { useCurrentUser } from 'Components/CurrentUser/CurrentUser';
import NoSSR from 'Components/NoSSR';
import ProviderFeedbacks from 'Components/ProviderFeedbacks/ProviderFeedbacks';
import { IKEA_SLUG } from 'common/CustomRoutes/Routes';
import config from 'config/config';
import { VerificationStatus } from 'pages/SPPublicProfile/SPPublicProfile.helpers';
import { Button } from 'uikit/Button';
import { TextP1 } from 'uikit/Text';
import analytics from 'utils/analytics/analytics';
import routes from 'utils/routeTranslator';

import WizardWrap from '../RequestWizard/WizardWrap';
import SelectServicesModal from '../SelectServicesModal/SelectServicesModal';
import ProfileActivationCard from './ProfileActivationCard/ProfileActivationCard';
import ProfileEditButton from './ProfileEditButton/ProfileEditButton';
import ProfileNavigation from './ProfileNavigation/ProfileNavigation';
import ProviderAbout from './ProviderAbout/ProviderAbout';
import ProviderCardView from './ProviderCardView/ProviderCardView';
import ProviderContacts from './ProviderContacts/ProviderContacts';
import ProviderExternalLinks from './ProviderExternalLinks/ProviderExternalLinks';
import ProviderGus from './ProviderGus/ProviderGus';
import ProviderInfo from './ProviderInfo/ProviderInfo';
import ProviderInsurance from './ProviderInsurance/ProviderInsurance';
import ProviderPrices from './ProviderPrices/ProviderPrices';
import {
  buildCompanyBadge,
  buildServicesTree,
  getExperienceBadges,
  providerNearbyCitiesQuery,
  ProviderNearbyCitiesResponse,
  ProviderProfileViewProps,
} from './ProviderProfileView.helpers';
import './ProviderProfileView.scss';
import ProviderPwF from './ProviderPwF';
import { ProviderSectionHeader } from './ProviderSectionHeader/ProviderSectionHeader';
import ProviderServiceArea from './ProviderServiceArea/ProviderServiceArea';
import ProviderServices, { ServiceNode } from './ProviderServices/ProviderServices';
import { useProfileQuery } from './useProfileQuery';

export const REVIEWS_HASH = 'opinie';

const ProviderProfileView: FC<ProviderProfileViewProps> = ({ profile, editable, premium, className }) => {
  const { currentUser } = useCurrentUser();
  const { data: citiesData } = useQuery<ProviderNearbyCitiesResponse>(providerNearbyCitiesQuery, {
    variables: {
      slug: profile.slug,
    },
  });

  const [categorySlug, setCategorySlug] = useState<string>('');
  const [isSelectServicesModalOpen, setIsSelectServicesModalOpen] = useState<boolean>(false);
  const location = useLocation();
  const history = useHistory();
  const reviewsWrapperRef = useRef<HTMLDivElement>(null);

  const contactProvider = (): void => {
    const onlySlug = hasOnlyService();
    if (onlySlug) {
      openWizardBySlug(onlySlug);
    } else {
      setIsSelectServicesModalOpen(true);
    }
    analytics.global.profileContactProvider('top', profile.id);
  };

  // maybe we should move wizard out from view?
  const openWizardBySlug = (slug: string): void => {
    setIsSelectServicesModalOpen(false);

    // redirect IKEA requests to landing page
    if (slug === IKEA_SLUG) {
      return history.push(routes.get('front::ikea.landing'));
    }

    setCategorySlug(slug);
    window.postMessage(
      {
        type: 'open-wizard',
      },
      window.location.origin
    );
  };

  useEffect(() => {
    if (location.hash === `#${REVIEWS_HASH}` && reviewsWrapperRef.current) {
      animateScroll.scrollTo(reviewsWrapperRef.current.offsetTop - 60); // 60 is offset of sticky header
    }
  }, [location.hash]);

  const servicesTree: ServiceNode[] = buildServicesTree(profile.specializations);

  const hasOnlyService = (): false | ServiceNode['slug'] => {
    const children = servicesTree[0].children;
    if (servicesTree.length === 1 && children?.length === 1) return children[0].slug;
    return false;
  };

  const companyBadge = profile.company && buildCompanyBadge(currentUser?.isProvider);
  const experienceBadges = getExperienceBadges(companyBadge ? [...profile.badges, companyBadge] : profile.badges);

  const isProviderGusVisible = profile.company?.verificationStatus === VerificationStatus.verified || editable;
  const showPayWithFixly = useProfileQuery();
  const isProviderPwFVisible = (profile.isPayWithFixlyRegistered || editable) && showPayWithFixly;
  const isProviderInsuranceVisible = profile.insurance?.active || editable;

  const getProfileSlug = () => {
    return profile.slugAlias || profile.slug;
  };

  const providerExternalLinks: string[] = [];
  const { websiteUrl, socialMediaUrl } = profile;

  if (websiteUrl) {
    providerExternalLinks.push(websiteUrl);
  } else if (editable) {
    const placeholderText = t`Strona internetowa`;
    providerExternalLinks.push(`placeholder:${placeholderText}`);
  }

  if (socialMediaUrl) {
    providerExternalLinks.push(socialMediaUrl);
  } else if (editable) providerExternalLinks.push('placeholder:Facebook');

  const isActivationCardVisible = !premium && editable;
  const isDirectRequestButtonVisible =
    !currentUser?.isProvider || (!editable && profile.id === String(currentUser.userId));

  const navigationItems = [
    {
      label: t`O mnie`,
      id: 'section-about',
    },
    {
      label: t`Opinie` + (profile.feedbacksCount ? ` • ${profile.feedbacksCount}` : ''),
      id: REVIEWS_HASH,
    },
    {
      label: t`Usługi`,
      id: 'section-services',
    },
    {
      label: t`Cennik`,
      id: 'section-prices',
    },
  ];

  if (editable) {
    const providerCardNavigationItem = {
      label: t`Wizytówka`,
      id: 'provider-card-preview',
    };
    navigationItems.splice(1, 0, providerCardNavigationItem);
  }

  const hostCN = classnames('ProviderProfileView', className);

  return (
    <div className={hostCN}>
      <div className="ProviderProfileView__layout">
        <ProviderInfo
          className="ProviderProfileView__cell"
          publicName={profile.publicName}
          companyName={profile.companyName}
          avatarUrl={profile.avatarUrl}
          coverUrl={profile.coverUrl}
          lastActivity={profile.lastActivity}
          cityName={profile.city?.name}
          regionName={profile.city?.region?.name}
          featured={profile.featured}
          feedbacksCount={profile.feedbacksCount}
          feedbackClientsCount={profile.feedbackClientsCount}
          rating={profile.rating}
          experience={experienceBadges}
          editable={editable}
          premium={premium}
          slug={profile.slugAlias || profile.slug}
        />
        <div className="ProviderProfileView__sidebar">
          <div className="ProviderProfileView__stickyInner">
            {isActivationCardVisible && (
              <ProfileActivationCard className="ProviderProfileView__card ProviderProfileView__card_dense">
                {profile.mapUrl && <ProviderServiceArea mapUrl={profile.mapUrl} />}
              </ProfileActivationCard>
            )}

            {!isActivationCardVisible && (
              <>
                <div className="ProviderProfileView__card ProviderProfileView__card_dense">
                  {isDirectRequestButtonVisible && (
                    <Button kind="cta" type="button" width="full" onClick={contactProvider}>
                      <Trans>Zapytaj o ofertę</Trans>
                    </Button>
                  )}
                  <NoSSR>
                    {editable && (
                      <ProviderSectionHeader
                        title={t`Dane kontaktowe`}
                        editButton={<ProfileEditButton id="section-contacts" />}
                      />
                    )}
                    <ProviderExternalLinks links={providerExternalLinks} />
                    <ProviderContacts
                      phone={profile.maskedPhone}
                      phoneVerified={profile.phoneVerified}
                      email={profile.email}
                      emailVerified={profile.emailVerified}
                      address={profile.address}
                      providerId={profile.id}
                      editable={editable}
                    />
                  </NoSSR>
                  {profile.mapUrl && (
                    <ProviderServiceArea nearbyCities={citiesData?.providerNearbyCities} mapUrl={profile.mapUrl} />
                  )}
                </div>
                {editable && (
                  <div className="ProviderProfileView__card ProviderProfileView__card_dense">
                    <ProviderSectionHeader
                      title={t`Twój adres na Fixly.pl`}
                      editButton={editable && <ProfileEditButton id="section-slug" />}
                    />
                    <TextP1 className="ProviderProfileView__slugText">
                      <Trans>Stwórz swój unikalny adres w serwisie</Trans>
                    </TextP1>
                    {Boolean(getProfileSlug()) && (
                      <div className="ProviderProfileView__slugLink">{`${config.SITE_URL}/${getProfileSlug()}`}</div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {isProviderGusVisible && <ProviderGus company={profile.company} className="ProviderProfileView__cell" />}
        {isProviderPwFVisible && (
          <ProviderPwF isRegistered={isProviderPwFVisible} editable={editable} className="ProviderProfileView__cell" />
        )}
        {isProviderInsuranceVisible && (
          <ProviderInsurance
            title={profile.insurance?.title}
            active={profile.insurance?.active}
            editable={editable}
            className="ProviderProfileView__cell"
          />
        )}
        <ProfileNavigation
          className="ProviderProfileView__cell ProviderProfileView__tabs ProviderProfileView__card"
          items={navigationItems}
        />
        <ProviderAbout
          className="ProviderProfileView__cell ProviderProfileView__card"
          id="section-about"
          summary={profile.shortDescription}
          description={profile.longDescription}
          photos={profile.gallery}
          editable={editable}
        />
        {editable && (
          <div className="ProviderProfileView__cell ProviderProfileView__card" id="provider-card-preview">
            <ProviderCardView profile={profile} premium={premium} />
          </div>
        )}
        <div className="ProviderProfileView__cell ProviderProfileView__card" ref={reviewsWrapperRef} id={REVIEWS_HASH}>
          <ProviderFeedbacks
            slug={profile.slug}
            id="section-feedbacks"
            isOwnProfile={String(currentUser?.userId) === profile.id}
            editable={editable}
          />
        </div>
        <ProviderServices
          id="section-services"
          slug={profile.slug}
          services={servicesTree}
          citySlug={profile.city?.isBig ? profile.city.slug : undefined}
          className="ProviderProfileView__cell ProviderProfileView__card"
          isProvider={currentUser?.isProvider}
          onServiceSelect={!currentUser?.isProvider ? openWizardBySlug : undefined}
          editable={editable}
        />
        <ProviderPrices
          id="section-prices"
          className="ProviderProfileView__cell ProviderProfileView__card"
          slug={profile.slug}
          prices={profile.prices}
          services={profile.specializations || []}
          editable={editable}
        />
      </div>
      {!currentUser?.isProvider && (
        <SelectServicesModal
          isOpen={isSelectServicesModalOpen}
          services={servicesTree}
          onServiceSelect={openWizardBySlug}
          handleClose={() => setIsSelectServicesModalOpen(false)}
        />
      )}
      {categorySlug && (
        <WizardWrap
          categorySlug={categorySlug}
          providerId={profile.id}
          externalBackAction={hasOnlyService() ? undefined : () => setIsSelectServicesModalOpen(true)}
        />
      )}
    </div>
  );
};

export default ProviderProfileView;
